import { VTextField } from "vuetify/lib";

var formatter = new Intl.NumberFormat("ru-RU", {
  style: "currency",
  currency: "RUB",
  minimumFractionDigits: 0,
});

export default {
  extends: VTextField,
  data: () => ({
    inputValue: null,
  }),
  props: {
    blurredFormat: {
      type: Function,
      default: (v) => {
        if (v) return formatter.format(parseFloat(v));
      },
    },
  },
  methods: {
    showValue() {
      if (!this.isFocused) {
        this.inputValue = this.lazyValue;
        this.lazyValue = this.blurredFormat(this.lazyValue);
      } else {
        this.lazyValue = this.inputValue;
      }
    },
  },
  watch: {
    isFocused() {
      this.showValue();
    },
    value() {
      // Handle v-model updates
      if (!this.isFocused) {
        this.showValue();
      }
    },
  },
  mounted() {
    this.showValue();
  },
};
