<template>
  <v-form ref="mainForm">
    <v-row class="mb-7">
      <v-col class="col-12 align-self-end">
        <v-row>
          <v-col class="d-flex">
            <v-card
              v-ripple="{ class: `accent--text` }"
              class="v-card_border d-flex flex-column align-start v-card_marker"
              :class="{ active: charter.status == 2 }"
              @click="setCharter(2)"
            >
              <span class="v-card__marker">Рекомендуем</span>
              <v-card-text class="pa-8">
                <v-card-title
                  class="subtitle-2 py-0 px-0 mb-4 text-uppercase text-break"
                  >Устав от Dokia
                </v-card-title>
                <span class="d-block mb-3"
                  >Мы сгенерируем готовый устав, который подойдет большинству
                  предпринимателей.</span
                >
                <span class="d-block mb-3"
                  >При этом вы можете использовать свой устав или внести правки
                  в сгенерированный нашим сервисом.</span
                >
                <v-btn
                  :depressed="charter.status == 2"
                  :color="
                    charter.status == 2 ? 'accent text--primary' : 'btn_gray'
                  "
                  small
                  class="text--primary mt-8"
                >
                  <v-icon v-if="charter.status == 2" small class="mr-2">
                    mdi-check
                  </v-icon>
                  {{ charter.status == 2 ? "Выбран" : "Выбрать" }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              v-ripple="{ class: `accent--text` }"
              class="v-card_border d-flex flex-column align-start v-card_marker"
              :class="{ active: charter.status == 1 }"
              @click="setCharter(1)"
            >
              <v-card-text class="pa-8">
                <v-card-title
                  class="subtitle-2 py-0 px-0 mb-4 text-uppercase text-break"
                  >типовой устав
                </v-card-title>
                <span class="d-block mb-3">Типовой устав — это удобно:</span>
                <div class="px-0 d-flex">
                  <span class="mr-4">
                    <v-icon size="18" class="black--text">mdi-check</v-icon>
                  </span>
                  <div class="pt-0 pb-3">
                    <span class="body-2">Экономия на разработке устава</span>
                  </div>
                </div>
                <div class="px-0 d-flex">
                  <span class="mr-4">
                    <v-icon size="18" class="black--text">mdi-check</v-icon>
                  </span>
                  <div class="pt-0 pb-3">
                    <span class="body-2">Юридически грамотный документ</span>
                  </div>
                </div>
                <div class="px-0 d-flex">
                  <span class="mr-4">
                    <v-icon size="18" class="black--text">mdi-check</v-icon>
                  </span>
                  <div class="pt-0 pb-3">
                    <span class="body-2"
                      >Не надо печатать и подавать в ФНС</span
                    >
                  </div>
                </div>
                <v-btn
                  :depressed="charter.status == 1"
                  :color="
                    charter.status == 1 ? 'accent text--primary' : 'btn_gray'
                  "
                  small
                  class="text--primary mt-8"
                >
                  <v-icon v-if="charter.status == 1" small class="mr-2">
                    mdi-check
                  </v-icon>
                  {{ charter.status == 1 ? "Выбран" : "Выбрать" }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mb-7" v-if="charter.status == 1">
      <v-col class="col-12 align-self-end">
        <div class="text-h6 my-2">Укажите номер устава</div>
        <p class="grey--text mb-4">
          Подберите типовой устав с помощью
          <a href="https://service.nalog.ru/statute/" target="_blank"
            >сервиса ФНС</a
          >
          и укажите его номер в поле ниже
        </p>
        <v-text-field
          :value="charter.number"
          @input="debounceInput($event, 'saveContent', charter, 'number')"
          filled
          background-color="field_bg"
          class="rounded-0 pb-8"
          hide-details="auto"
          :rules="[rules.required, rules.numbers]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-alert v-if="errors.charter" dense outlined type="error">
      {{ errors.charter }}
    </v-alert>

    <v-row class="mt-8 mt-sm-14">
      <v-col>
        <v-btn @click="back" color="secondary" class="text--primary">
          Назад
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="accent"
          class="text--primary"
          @click="next"
          :disabled="!$store.state.isFormValid.charter"
        >
          Сохранить &rarr;
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      charter: {
        status: null,
        number: null,
      },
      loading: false,
      rules: {
        required: (v) =>
          v !== null ||
          (v && v.trim() !== "") ||
          "Это поле обязательно для заполнения",
        numbers: (v) =>
          (v > 0 && v <= 36) ||
          this.charter.status == 2 ||
          "Укажите номер типового устава от 1 до 36",
      },
      errors: {
        charter: null,
      },
    };
  },
  watch: {
    "charter.status"() {
      this.preValid();
    },
    "charter.number"() {
      this.preValid();
    },
  },
  mounted() {
    if (localStorage.getItem("charter")) {
      this.charter = JSON.parse(localStorage.getItem("charter"));
    }
    if (this.$store.state.document.charter > 0) {
      this.charter.status;
    }
    if (this.$store.state.formValidation) {
      var self = this;
      setTimeout(function () {
        if (!self.next(false)) {
          self.$store.commit("formValidation", false);
        }
      }, 0);
    }
  },
  methods: {
    setCharter(val) {
      this.$set(this.charter, "status", val);
      if (val !== 1) {
        this.$set(this.charter, "number", null);
      }
      this.$set(this.errors, "charter", null);
      localStorage.setItem("charter", JSON.stringify(this.charter));
    },
    back() {
      this.$emit("pushPrev");
    },
    next() {
      if (!this.validate(true)) {
        return false;
      }
      this.loading = true;
      if (window.dataLayer !== undefined) {
        if (this.charter.status == 1) {
          window.dataLayer.push({
            event: "charterSentTypical",
            secEvent: this.charter.number,
          });
        } else {
          window.dataLayer.push({ event: "charterSentDokia" });
        }
      }
      localStorage.setItem("charter", JSON.stringify(this.charter));
      this.$store.commit("updateCharter", { charter: this.charter });
      this.$emit("pushNext");
      this.loading = false;
      return true;
    },
    preValid() {
      if (
        this.charter.status == 2 ||
        (this.charter.number > 0 && this.charter.number <= 36)
      ) {
        this.$store.commit("isFormValid", ["charter", true]);
      } else {
        this.$store.commit("isFormValid", ["charter", false]);
      }
    },
    validate(witherror = true) {
      var result = this.$refs.mainForm.validate();
      if (!witherror && !result) {
        this.$refs.mainForm.resetValidation();
      }
      if (witherror && this.charter.status == null) {
        result = false;
        Vue.set(this.errors, "charter", "Необходимо выбрать устав");
      }
      setTimeout(
        function () {
          this.$store.commit("isFormValid", ["charter", result]);
        }.bind(this),
        0
      );
      return result;
    },
    saveContent(e, obj, key) {
      this.$set(obj, key, e);
      const payload = this.charter;
      localStorage.setItem("charter", JSON.stringify(payload));
    },
  },
};
</script>
