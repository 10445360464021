<template>
  <v-form ref="mainForm" v-model="$store.state.isFormValid.name">
    <v-text-field
      :value="name.title"
      @input="debounceInput($event, 'saveContent', name, 'title')"
      label="Наименование без кавычек"
      filled
      background-color="field_bg"
      class="rounded-0 pb-8"
      hide-details="auto"
      :rules="[rules.required, rules.cyrillic, rules.match]"
    />
    <div v-show="name.title">
      <v-row class="flex-column flex-sm-row">
        <template v-if="!name.short && !showShortName">
          <v-col class="col-sm-4 pb-0 pb-sm-3">
            <p class="grey--text lighten-2 mb-0 mb-sm-4">
              Сокращенное наименование
            </p>
          </v-col>
          <v-col class="col-sm-8">
            <p v-show="name.title" class="black--text">
              ООО "{{ name.title }}"
              <small
                >(<a @click="showShort" class="blue--text text-decoration-none"
                  >Изменить сокращённое наименование</a
                >)</small
              >
            </p>
          </v-col>
        </template>
        <template v-else>
          <v-col class="col-10 pb-0 pb-sm-3">
            <v-text-field
              v-if="name.short || showShortName"
              :value="name.short"
              @input="debounceInput($event, 'saveContent', name, 'short')"
              label="Сокращенное наименование"
              filled
              background-color="field_bg"
              class="rounded-0 pb-8"
              hide-details="auto"
              :rules="[rules.cyrillicWithQuotes]"
            ></v-text-field>
          </v-col>
          <v-col class="col-2 d-flex align-center">
            <v-btn icon @click="hideShort" class="_white-xs mb-8">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </template>
      </v-row>
      <v-row class="flex-column flex-sm-row mb-0">
        <v-col class="col-sm-4 pb-0 pb-sm-3">
          <p class="grey--text lighten-2 mb-0 mb-sm-4">Полное наименование</p>
        </v-col>
        <v-col class="col-sm-8">
          <p v-show="name.title" class="black--text mb-0 mb-sm-4">
            Общество с ограниченной ответственностью "{{ name.title }}"
          </p>
        </v-col>
      </v-row>
    </div>
    <div class="text-h5 my-4">Юридический адрес</div>
    <div class="text-h6 my-4">1. Адрес с точностью до здания</div>
    <!-- <v-checkbox
      v-model="name.need_to_rent"
      label="Арендовать адрес"
    ></v-checkbox> -->
    <p v-if="name.need_to_rent" class="text-body-2 mb-2">
      Мы свяжемся с вами по вопросу аренды адреса, а пока можете продолжить
      заполнение документов
    </p>
    <template v-else>
      <p class="grey--text mb-2">Выберите вариант из подсказок.</p>
      <v-row class="mt-0">
        <v-col id="address" cols="12" class="tooltip-wrapper">
          <v-tooltip bottom nudge-bottom="1">
            <template v-slot:activator="{ on, attrs }">
              <v-autocomplete
                :value="name.address.source"
                @input="
                  debounceInput($event, 'saveContent', name.address, 'source')
                "
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                :disabled="name.need_to_rent"
                filled
                no-filter
                class="rounded-0 pb-5"
                background-color="field_bg"
                hide-details="auto"
                hide-no-data
                :rules="[rules.required]"
                @blur="clearIfEmpty($event, name.address)"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <span
              >Укажите адрес до здания, а внутри помещения выберите из полей
              ниже</span
            >
          </v-tooltip>
        </v-col>
      </v-row>
      <div class="text-h6 my-4">2. Помещение внутри здания</div>
      <p class="grey--text mb-2">
        Как можно точнее. Например: этаж 5, офис 101, помещение IX
      </p>
      <draggable v-model="name.paths">
        <v-row class="mt-0 mb-5" v-for="(path, key) in name.paths" :key="key">
          <v-col cols="1" class="d-flex align-center">
            <v-btn icon class="_white-xs" style="cursor: grabbing">
              <v-icon>mdi-drag</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="addressTypes"
              item-text="name"
              item-value="type"
              item-disabled="disabled"
              :value="path.type"
              @input="debounceInput($event, 'saveContent', path, 'type')"
              class="rounded-0"
              background-color="field_bg"
              hide-details="auto"
              filled
            ></v-select>
          </v-col>
          <v-col cols="7">
            <v-text-field
              :value="path.str"
              @input="debounceInput($event, 'saveContent', path, 'str')"
              filled
              background-color="field_bg"
              class="rounded-0"
              hide-details="auto"
              :rules="
                [0, 2, 4, 5, 6, 7, 8, 9, 10, 13].includes(path.type)
                  ? []
                  : [rules.cyrillicWithQuotes]
              "
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="d-flex align-center">
            <v-btn icon class="_white-xs" @click="$delete(name.paths, key)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </draggable>
      <span
        class="link mb-8 d-inline-block"
        v-if="name.paths && name.paths.length <= 3"
        @click="name.paths.push({ type: null, str: '' })"
      >
        Добавить
      </span>
      <v-row class="mt-0 flex-column flex-sm-row">
        <v-col class="col col-sm-3 col-xl-2 pb-0 pb-sm-3">
          <p class="grey--text lighten-2 mb-0 mb-sm-4">Полный адрес</p>
        </v-col>
        <v-col class="col col-sm-9 col-xl-10">
          <p class="black--text">{{ fullAddress }}</p>
        </v-col>
      </v-row>
    </template>
    <v-row class="mt-5 mt-sm-14">
      <v-col>
        <v-btn color="secondary" class="text--primary" @click="back()"
          >Назад
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="accent"
          class="text--primary"
          :loading="loading"
          @click="next"
          :disabled="!$store.state.isFormValid.name"
        >
          Сохранить &rarr;
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
  },
  data() {
    return {
      items: [],
      addressTypes: [
        {
          type: 0,
          name: "Помещение",
          short: "помещ.",
          disabled: false,
        },
        {
          type: 1,
          name: "Этаж",
          short: "этаж",
          disabled: false,
        },
        {
          type: 2,
          name: "Офис",
          short: "офис",
          disabled: false,
        },
        {
          type: 3,
          name: "Квартира",
          short: "кв.",
          disabled: false,
        },
        {
          type: 4,
          name: "Комната",
          short: "ком.",
          disabled: false,
        },
        {
          type: 5,
          name: "Рабочий участок",
          short: "раб.уч.",
          disabled: false,
        },
        {
          type: 6,
          name: "Склад",
          short: "скл.",
          disabled: false,
        },
        {
          type: 7,
          name: "Торговый зал",
          short: "торг.зал",
          disabled: false,
        },
        {
          type: 8,
          name: "Цех",
          short: "цех",
          disabled: false,
        },
        {
          type: 9,
          name: "Павильон",
          short: "пав.",
          disabled: false,
        },
        {
          type: 10,
          name: "Подвал",
          short: "подв.",
          disabled: false,
        },
        {
          type: 11,
          name: "Котельная",
          short: "кот.",
          disabled: false,
        },
        {
          type: 12,
          name: "Погреб",
          short: "п-б",
          disabled: false,
        },
        {
          type: 13,
          name: "Гараж",
          short: "г-ж",
          disabled: false,
        },
      ],
      isLoading: false,
      search: null,
      showShortName: false,
      name: {
        title: null,
        short: "",
        need_to_rent: false,
        address: {},
        paths: [],
      },
      loading: false,
      rules: {
        required: (v) =>
          (typeof v !== "undefined" && v !== null) ||
          (v && v.trim() !== "") ||
          "Это поле обязательно для заполнения",
        match: (v) =>
          (v &&
            v.toLowerCase().indexOf("ооо") !== 0 &&
            v.toLowerCase().indexOf("общество") !== 0) ||
          "Наименование юрлица указывается без организационно-правовой формы",
        cyrillic: (v) =>
          /^[-а-яА-ЯёЁ0-9\s.&,]+$/gi.test(v) ||
          "Допускаются только кириллические символы, цифры и знаки препинания",
        cyrillicWithQuotes: (v) =>
          /^[-а-яА-ЯёЁ0-9\s.&,"]+$/gi.test(v) ||
          v == "" ||
          "Допускаются только кириллические символы, цифры и знаки препинания и двойные кавычки",
      },
    };
  },
  computed: {
    fullAddress() {
      var self = this,
        address = this.name.address.source ? this.name.address.source : "";
      if (this.name.paths) {
        this.name.paths.forEach(function (path) {
          if (
            self.name.address.source != "" &&
            path.str != "" &&
            path.type != null
          ) {
            address += ", ";
            var result = self.addressTypes.find((obj) => {
              return obj.type === path.type;
            });
            if (result) {
              address += result.short;
              address += " " + path.str;
            }
          }
        });
      }
      return address;
    },
  },
  watch: {
    search(value) {
      this.isLoading = true;
      let options = {
        method: "post",
        url: process.env.VUE_APP_API + "/s/address",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({
          query: value,
          count: 10,
          to_bound: {
            value: "house",
          },
        }),
      };
      this.axios(options).then((result) => {
        let suggestions = result.data.suggestions.map(function (suggestion) {
          return suggestion.value;
        });
        this.items = suggestions;
        this.isLoading = false;
      });
    },
    "name.address.source"(value) {
      if (value) {
        let options = {
          method: "post",
          url: process.env.VUE_APP_API + "/s/address",
          mode: "cors",
          data: JSON.stringify({ query: value }),
        };
        this.axios(options).then((result) => {
          this.name.address = result.data;
          if (!this.name.paths) {
            this.$set(this.name, "paths", [
              { str: "", type: null },
              { str: "", type: null },
            ]);
          }
        });
      }
    },
    "name.need_to_rent"(value) {
      if (value) {
        Vue.set(this.name.address, "source", null);
      }
      this.saveContent(value, this.name, "need_to_rent");
    },
    "name.paths": {
      handler: function (value, val) {
        if (value == null || typeof value == "undefined" || value.length <= 0) {
          return false;
        }
        let types = [];
        this.addressTypes.forEach(function (type) {
          type.disabled = false;
        });
        value.forEach(function (path) {
          if (typeof types[path.type] == "undefined") {
            types[path.type] = 1;
          } else {
            types[path.type]++;
          }
        });
        // Проставляем неактивность элементам типа 0 и 3,
        // если таковых 2 и более
        if (types[0] >= 2 || types[4] >= 2 || types[0] + types[4] >= 2) {
          this.addressTypes[0].disabled = true;
          this.addressTypes[4].disabled = true;
        }
        // Проставляем неактивность всем уже выбранным элементам
        this.addressTypes.forEach(function (type) {
          if (types[type.type] > 0) {
            type.disabled = true;
          }
        });
        this.$set(types, 0, 0);
        this.$set(types, 4, 0);
        // Проставляем неактивность всем элементам кроме 0 и 3 типа,
        // в случае, если таковых 2 и более
        if (
          types.reduce(function (sum, current) {
            return sum + current;
          }, 0) >= 2
        ) {
          this.addressTypes.forEach(function (type) {
            if (type.type != 0 && type.type != 4) {
              type.disabled = true;
            }
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (localStorage.getItem("name")) {
      let name = JSON.parse(localStorage.getItem("name"));
      this.name = name;
      if (name.address.source) {
        this.items = [name.address.source];
      }
    }
    // Оставляем два пустых поля, если части адреса не заполнены
    if (this.name.paths.length < 2) {
      for (var i = this.name.paths.length; i < 2; i++) {
        this.$set(this.name.paths, i, { str: "", type: null });
      }
    }
    if (this.$store.state.formValidation) {
      var self = this;
      setTimeout(function () {
        if (!localStorage.getItem("name") || !self.next(false)) {
          self.$store.commit("formValidation", false);
        }
      }, 0);
    }
  },
  // computed: {
  //   formComplete() {
  //     return this.name.title &&
  //     (this.name.address.fias_level > 5 || this.name.need_to_rent)
  //       ? true
  //       : false;
  //   },
  // },
  methods: {
    back() {
      this.$emit("pushPrev");
    },
    next(witherror = true) {
      if (!this.validate(witherror)) {
        return false;
      }
      this.loading = true;

      // Проверяем части адреса на наличие пустых полей
      if (this.name.paths) {
        for (let i = this.name.paths.length - 1; i > -1; i--) {
          if (this.name.paths[i].str == "" || this.name.paths[i].type == null) {
            this.$delete(this.name.paths, i);
          }
        }
      }

      localStorage.setItem("name", JSON.stringify(this.name));
      const payload = {
        title: this.name.title,
        short: this.name.short,
        need_to_rent: this.name.need_to_rent,
        address: this.name.address,
        paths: this.name.paths,
      };
      if (window.dataLayer !== undefined) {
        window.dataLayer.push({ event: "nameSent" });
      }
      this.$store.commit("updateName", payload);
      this.$emit("pushNext");
      this.loading = false;
      return true;
    },
    showShort() {
      Vue.set(this.name, "short", 'ООО "' + this.name.title + '"');
      this.showShortName = !this.showShortName;
    },
    hideShort() {
      this.name.short = "";
      this.showShortName = !this.showShortName;
    },
    validate(witherror = true) {
      var result = this.$refs.mainForm.validate();
      if (!witherror && !result) {
        this.$refs.mainForm.resetValidation();
      }
      setTimeout(
        function () {
          this.$store.commit("isFormValid", ["name", result]);
        }.bind(this),
        0
      );
      return result;
    },
    clearIfEmpty(e, obj) {
      if (!e.target.value) {
        Vue.set(obj, "source", e.target.value);
      }
    },

    saveContent(e, obj, key) {
      if (e) {
        this.$set(obj, key, e);
        var payload = this.name;
        localStorage.setItem("name", JSON.stringify(payload));
      }
      payload = {
        title: this.name.title,
        short: this.name.short,
        need_to_rent: this.name.need_to_rent,
        address: this.name.address,
        paths: this.name.paths,
      };
      this.$store.commit("updateName", payload);
    },
  },
};
</script>
