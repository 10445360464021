var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stepper pb-4 pb-sm-0"},_vm._l((_vm.steps),function(step,i){return _c('span',{key:step.name},[(_vm.isActive(i))?[(_vm.checkStep(i))?_c('router-link',{key:i,staticClass:"text-subtitle-1 d-flex text-decoration-none mb-0 mb-sm-4 mr-7 mr-sm-0",class:[
          _vm.currentstep === i + 1 ? 'active' : '',
          _vm.checkStep(i) ? '' : 'text--disabled',
        ],attrs:{"to":step.link},on:{"click":function($event){return _vm.changeStep(i)}}},[_c('v-icon',{class:_vm.currentstep === i + 1
              ? 'black--text'
              : _vm.currentstep <= i
              ? ''
              : 'yellow--text',attrs:{"dense":"","medium":""}},[_vm._v(" mdi-checkbox-marked-circle ")]),_c('span',{staticClass:"ml-3 ml-sm-5 d-block"},[_vm._v(_vm._s(step.title))])],1):_c('span',{key:i,staticClass:"text-subtitle-1 d-flex text-decoration-none text--disabled mb-0 mb-sm-4 mr-7 mr-sm-0"},[_c('v-icon',{attrs:{"dense":"","medium":""}},[_vm._v(" mdi-checkbox-marked-circle ")]),_c('span',{staticClass:"ml-3 ml-sm-5 d-block"},[_vm._v(_vm._s(step.title))])],1)]:_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }