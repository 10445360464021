<template>
  <v-card
    class="text-center _drag"
    :class="[{ '_no-bg': !hover }, { _error: error }]"
    @dragover.prevent="hover = true"
    @dragenter.prevent="hover = true"
    @dragleave.prevent="hover = false"
    @drop.prevent="onDrop($event)"
    @click="onClick()"
  >
    <input
      ref="file"
      type="file"
      hidden
      accept="image/*"
      @change="onDrop($event)"
    />
    <v-card-text class="pb-1"
      ><p class="subtitle-1 text-break mb-0 text--black">
        {{ title }}
      </p></v-card-text
    >
    <v-card-actions class="justify-center">
      <v-btn icon>
        <v-icon id="upload-button" size="44">{{ icon }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text class="pt-1"
      ><p class="body-2 mb-0 grey--text">{{ description }}</p></v-card-text
    >
  </v-card>
</template>

<script>
export default {
  name: "Dragfile",
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    icon: String,
  },
  data() {
    return {
      hover: false,
      error: "",
      currentFile: null,
    };
  },
  computed: {
    getFileSize() {
      var size = 0;
      if (this.currentFile) {
        size = this.currentFile.size / 1024 / 1024;
      }
      return size.toFixed(1);
    },
  },
  methods: {
    onClick() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    onDrop(e) {
      this.hover = false;
      this.currentFile = e.dataTransfer
        ? e.dataTransfer.files[0]
        : e.target.files[0];
      if (this.currentFile.size > 8388608) {
        this.error = "Размер файла не должен превышать 8 Мб";
        this.currentFile = null;
      } else if (
        this.currentFile.type != "image/jpeg" &&
        this.currentFile.type != "image/png" &&
        this.currentFile.type != "image/jpg"
      ) {
        this.error = "Тип файла должен быть: .jpeg, .jpg, .png";
        this.currentFile = null;
      } else {
        this.error = "";
      }
      if (!this.error) {
        this.$emit("success", this.currentFile);
      }
    },
  },
};
</script>
