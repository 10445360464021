<template>
  <v-form>
    <p class="text-body-2">
      Рекомендуем начать с упрощенной системой налогообложения (УСН):
      минимальная отчетность, минимальные налоги, легкая бухгалтерская
      отчетность.
    </p>
    <v-row class="mb-7">
      <v-col class="col-12 col-md-7 align-self-end">
        <p class="title-with-badge">
          «Упрощенка»
          <v-badge inline color="success" content="Рекомендуем" class="ml-1" />
        </p>
        <v-row>
          <v-col>
            <v-card
              v-ripple="{ class: `accent--text` }"
              class="v-card_fix-height v-card_border d-flex align-center justify-center"
              :class="{ active: taxes.main == 1 }"
              @click="setTaxes(1)"
            >
              <v-card-title
                class="flex-column align-content-center body-2 py-0 px-0 justify-space-between flex-nowrap"
              >
                <span class="text-h5 text-xl-h3 mb-2">УСН 6%</span>
                с дохода
                <v-card-actions class="px-0 pb-0 pt-2 pt-sm-4 pb-xl-2">
                  <v-btn
                    :depressed="taxes.main == 1"
                    :color="
                      taxes.main == 1 ? 'accent text--primary' : 'btn_gray'
                    "
                    small
                    class="text--primary"
                  >
                    <v-icon v-if="taxes == 1" small class="mr-2">
                      mdi-check
                    </v-icon>
                    {{ taxes.main == 1 ? "Выбран" : "Выбрать" }}
                  </v-btn>
                </v-card-actions>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              v-ripple="{ class: `accent--text` }"
              :class="{ active: taxes.main == 2 }"
              class="v-card_fix-height v-card_border d-flex align-center justify-center"
              @click="setTaxes(2)"
            >
              <v-card-title
                class="flex-column align-content-center text-center text-break body-2 py-0 px-0 justify-space-between flex-nowrap"
              >
                <span class="text-h5 text-xl-h3 mb-2">УСН 15%</span>
                доходы минус расходы
                <v-card-actions class="px-0 pb-0 pt-2 pt-sm-4 pb-xl-2">
                  <v-btn
                    :depressed="taxes.main == 2"
                    :color="
                      taxes.main == 2 ? 'accent text--primary' : 'btn_gray'
                    "
                    small
                    class="text--primary"
                  >
                    <v-icon v-if="taxes.main == 2" small class="mr-2">
                      mdi-check
                    </v-icon>
                    {{ taxes.main == 2 ? "Выбран" : "Выбрать" }}
                  </v-btn>
                </v-card-actions>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="align-self-end">
        <p class="title-with-badge">Общая система налогообложения</p>
        <v-card
          v-ripple="{ class: `accent--text` }"
          :class="{ active: taxes.main == 3 }"
          class="v-card_fix-height v-card_border d-flex align-center justify-center"
          @click="setTaxes(3)"
        >
          <v-card-title
            class="flex-column text-body-2 align-content-center py-0 px-0 justify-space-between flex-nowrap"
          >
            <span class="text-h3 mb-2 mt-xl-4">ОСНО</span>
            <v-card-actions class="px-0 pb-0 pt-2 pt-sm-4 pb-xl-2">
              <v-btn
                :depressed="taxes.main == 3"
                :color="taxes.main == 3 ? 'accent text--primary' : 'btn_gray'"
                small
                class="text--primary"
              >
                <v-icon v-if="taxes.main == 3" small class="mr-2">
                  mdi-check
                </v-icon>
                {{ taxes.main == 3 ? "Выбран" : "Выбрать" }}
              </v-btn>
            </v-card-actions>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <p v-if="taxes.main">
      {{ taxesDescription[taxes.main - 1] }}
    </p>
    <v-alert v-if="errors.taxes" dense outlined type="error">
      {{ errors.taxes }}
    </v-alert>

    <v-row class="mt-8 mt-sm-14">
      <v-col>
        <v-btn @click="back()" color="secondary" class="text--primary">
          Назад
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="accent"
          class="text--primary"
          @click="next"
          :disabled="!$store.state.isFormValid.taxes"
        >
          Сохранить &rarr;
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      taxes: {
        main: null,
        sub: null,
      },
      loading: false,
      taxesDescription: [
        "Подходит компаниям, не имеющим больших расходов и желающим максимально упростить налоговый учет: вы платите налоги только с оборота и можете не учитывать исходящие платежи.",
        "Подходит компаниям, расходы которых составляют 60% и более от доходов. Налог может быть уменьшен на суммы страховых взносов, уплаченных в ПФР и ФОМС за сотрудников ООО.",
        "ОСНО подойдет вам в том случае, если вы работаете с крупными компаниями, которые тоже применяют этот режим. Для них важно, чтобы вы платили НДС – в этом случае они смогут возместить этот налог, который платят сами.",
      ],
      errors: {
        taxes: null,
      },
    };
  },
  watch: {
    taxes(val) {
      if (val) {
        Vue.set(this.errors, "taxes", null);
      }
      this.preValid();
    },
  },
  mounted() {
    if (localStorage.getItem("taxes")) {
      this.taxes = JSON.parse(localStorage.getItem("taxes"));
      if (Number.isInteger(this.taxes)) {
        this.taxes = {
          main: this.taxes,
          sub: null,
        };
      }
    }
    if (this.$store.state.formValidation) {
      var self = this;
      setTimeout(function () {
        if (!self.next(false)) {
          self.$store.commit("formValidation", false);
        }
      }, 0);
    }
  },
  methods: {
    setTaxes(id) {
      this.$set(this.taxes, "main", id);
      localStorage.setItem("taxes", JSON.stringify(this.taxes));
      this.preValid();
    },
    back() {
      this.$emit("pushPrev");
    },
    next(witherror = true) {
      if (!this.validate(witherror)) {
        return false;
      }
      this.loading = true;
      if (window.dataLayer !== undefined) {
        window.dataLayer.push({ event: "taxSent" });
      }
      localStorage.setItem("taxes", JSON.stringify(this.taxes));
      this.$store.commit("updateTaxes", { taxes: this.taxes });
      this.$emit("pushNext");
      this.loading = false;
      return true;
    },
    preValid() {
      if (this.taxes.main !== null) {
        this.$store.commit("isFormValid", ["taxes", true]);
        return true;
      } else {
        this.$store.commit("isFormValid", ["taxes", false]);
        return false;
      }
    },
    validate(witherror = true) {
      let result = true;
      if (!this.preValid()) {
        result = false;
      }
      if (witherror && !result) {
        if (!this.taxes) {
          Vue.set(
            this.errors,
            "taxes",
            "Необходимо выбрать систему налогообложения"
          );
        }
      }
      setTimeout(
        function () {
          this.$store.commit("isFormValid", ["taxes", result]);
        }.bind(this),
        0
      );
      return result;
    },
  },
};
</script>

<style scoped>
/* .recommend {
      background-color: #99e55e;
      border-radius: 3px;
      text-transform: uppercase;
      color: black;
      padding: 5px 10px;
      font-size: 11px;
    }
    .active {
      background-color: #ffdf39;
      border: 1px solid #000000;
      background-color: white;
    } */
</style>
