<template>
  <v-app class="mh-reset">
    <v-dialog v-model="loginPopup" persistent max-width="480">
      <v-card>
        <v-card-title class="headline d-flex justify-space-between">
          <span class="text-h4">Авторизация</span>
          <v-btn
            class="d-flex justify-end pa-0"
            color="primary"
            text
            @click="
              loginPopup = !loginPopup;
              form.response.message = form.response.status = null;
            "
          >
            <v-icon size="32"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="loginForm" class="pa-6 pt-1" @submit.prevent="submit()">
          <v-text-field
            v-model="form.phone"
            v-mask="'+7 ### ###-##-##'"
            label="Телефон"
            placeholder="+7"
            required
            :disabled="form.status"
            :rules="[rules.required, rules.phone]"
          />
          <v-text-field
            v-if="form.status"
            v-model="form.code"
            type="text"
            label="Код подтверждения"
            required
            :rules="[rules.required, rules.code]"
            @keydown.enter.prevent="submit()"
          />
          <v-subheader
            v-if="
              form.countdown > 0 &&
              form.status &&
              form.response.status != 'success'
            "
            class="pa-0 pb-3"
          >
            Повторить отправку через {{ form.countdown }} секунд
          </v-subheader>
          <v-alert v-if="form.response.message" :type="form.response.status">
            {{ form.response.message }}
          </v-alert>
          <a
            v-else-if="
              form.countdown <= 0 &&
              form.status &&
              form.response.status != 'success'
            "
            class="d-block"
            href="javascript:;"
            @click="form.status = false"
            >Повторить отправку СМС
          </a>
          <v-btn
            v-if="!form.status"
            color="accent"
            class="mt-4 mr-4 text--primary"
            @click="getCode()"
          >
            Получить пароль по СМС
          </v-btn>
          <v-btn
            v-if="form.response.status != 'success' && form.status"
            color="accent"
            class="mt-4 mr-4 text--primary"
            @click="login()"
          >
            Войти
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: "Widget",
  data() {
    return {
      loginPopup: false,
      form: {
        phone: null,
        code: null,
        response: {
          status: null,
          message: null,
        },
        countdown: 0,
        status: false,
      },
      rules: {
        required: (v) =>
          (v && v.trim() !== "") || "Это поле обязательно для заполнения",
        phone: (v) =>
          /\+7 ([0-9]{3}) ([0-9]{3})-([0-9]{2})-([0-9]{2})/gi.test(v) ||
          "Некорректно введен номер телефона",
        code: (v) =>
          /([0-9]{4})/gi.test(v) || "Некорректно введен код подтверждения",
      },
    };
  },
  mounted() {
    $(document).on(
      "click",
      ".js-modal",
      function () {
        this.loginPopup = !this.loginPopup;
      }.bind(this)
    );
  },
  methods: {
    validate() {
      return this.$refs.loginForm.validate();
    },
    submit() {
      if (!this.validate()) {
        return false;
      }
      if (this.form.status) {
        this.login();
      } else {
        this.getCode();
      }
    },
    getCode() {
      if (!this.validate()) {
        return false;
      }
      var self = this;
      const options = {
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: process.env.VUE_APP_API + "/auth/send",
        mode: "cors",
        data: { phone: this.form.phone },
      };
      this.axios(options)
        .then(() => {
          self.form.response.status = self.form.response.message = null;
          self.form.status = true;
          self.form.countdown = 59;
          let timer = setInterval(function () {
            if (self.form.countdown > 0) {
              self.form.countdown--;
            } else {
              clearInterval(timer);
            }
          }, 1000);
        })
        .catch(function (error) {
          if (error.response.data) {
            self.form.response = error.response.data;
          }
          // console.log(error);
        });
    },
    logout() {
      this.$auth.logout({
        makeRequest: true,
        redirect: false,
      });
      this.form.login = null;
      this.form.code = null;
      this.form.status = false;
      this.form.response.status = null;
      this.form.response.message = null;
    },
    login() {
      var self = this;
      this.$auth
        .login({
          data: { phone: this.form.phone, code: this.form.code },
          staySignedIn: true,
          fetchUser: true,
          rememberMe: true,
          redirect: false,
        })
        .then(() => {
          self.form.response.message = "Успешный вход";
          self.form.response.status = "success";

          $.ajax({
            type: "POST",
            url: process.env.VUE_APP_SITE + "/wp-admin/admin-ajax.php",
            data: {
              action: "auth_by_token",
              token: localStorage.getItem("auth_token"),
            },
            success: function () {
              location.reload();
            },
          });

          setTimeout(function () {
            self.loginPopup = !self.loginPopup;
            self.form.response.message = self.form.response.status = null;
          }, 2000);
          if (window.dataLayer !== undefined) {
            window.dataLayer.push({ event: "userLogin" });
          }
        })
        .catch(function (error) {
          if (error.response) {
            self.form.response = error.response.data;
          }
        });
    },
  },
};
</script>
<style lang="scss">
//@import '~vuetify/src/styles/styles.sass';
@import "styles/variables.scss";
@import "styles/main.scss";
</style>
