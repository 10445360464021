<template>
  <v-app>
    <v-main app class="height-100">
      <top-menu />
      <v-container fluid fill-height align-start class="py-0 px-0 px-md-3">
        <v-container fill-height align-start class="pa-0 px-lg-3">
          <router-view />
        </v-container>
      </v-container>
    </v-main>
    <!-- <sidebar-menu v-model="drawer" /> -->
  </v-app>
</template>

<script>
import TopMenu from "@/components/TopMenu";
// import FooterMenu from "@/components/FooterMenu";
// import SidebarMenu from "@/components/SidebarMenu";

export default {
  name: "App",
  components: {
    TopMenu,
    // FooterMenu,
    // SidebarMenu,
  },
  data() {
    return {
      // drawer: false,
    };
  },
};
</script>
<style lang="scss">
//@import '~vuetify/src/styles/styles.sass';
@import "styles/variables.scss";
@import "styles/main.scss";

/* hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ddd;
    margin: 30px 0;
    padding: 0;
  }
  h1,
  h2,
  h3,
  h4,
  p {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
  }
  h1 {
    color: #000;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 40px;
  }
  h2 {
    color: #000;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  p {
    color: #828282;
    font-size: 16px;
    line-height: 20px;
  }
  .secondary {
    background-color: #f9f9f9;
  }
  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: #fff !important;
  }
  .accent > .v-btn__content {
    color: black;
  }
  .col {
    padding-top: 0;
    padding-bottom: 0;
  } */
</style>
