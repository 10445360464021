import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import Auth from "@websanova/vue-auth";
import AuthBasic from "@websanova/vue-auth/drivers/auth/bearer.js";
import HttpVueResource from "@websanova/vue-auth/drivers/http/axios.1.x.js";
import RouterVueRouter from "@websanova/vue-auth/drivers/router/vue-router.2.x.js";
import App from "./App.vue";
import Widget from "./Widget.vue";
import Okved from "./Okved.vue";
import routes from "./router/index.js";
import routesWidget from "./router/widget.js";
import vuetify from "./plugins/vuetify";
import { store } from "./store/store";
import { VueMaskDirective } from "v-mask";

Vue.use(VueRouter);
var router;
if (document.getElementById("app")) {
  router = new VueRouter({
    base: process.env.BASE_URL,
    routes: routes,
  });
  router.afterEach((to, from) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
} else if (
  document.getElementById("auth") ||
  document.getElementById("okved")
) {
  router = new VueRouter({
    base: process.env.BASE_URL,
    routes: routesWidget,
    mode: "history",
    hash: false,
  });
}
Vue.router = router;

Vue.directive("mask", VueMaskDirective);
Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_API;
Vue.use(VueAxios, axios);

var debounceInputPlugin = {
  install(Vue, options) {
    Vue.prototype.debounceInput = function (e, callback, obj, key) {
      var self = this;
      clearTimeout(this.$root.debounce);
      this.$root.debounce = setTimeout(function () {
        callback ? self[callback](e, obj, key) : self.saveContent();
      }, 10);
    };
  },
};
Vue.use(debounceInputPlugin);

var checkInnPlugin = {
  install(Vue, options) {
    Vue.prototype.checkInn = function (value) {
      if (value.length != 12) {
        return false;
      }
      var firstNumber =
        ((value[0] * 7 +
          value[1] * 2 +
          value[2] * 4 +
          value[3] * 10 +
          value[4] * 3 +
          value[5] * 5 +
          value[6] * 9 +
          value[7] * 4 +
          value[8] * 6 +
          value[9] * 8) %
          11) %
        10;
      var secondNumber =
        ((value[0] * 3 +
          value[1] * 7 +
          value[2] * 2 +
          value[3] * 4 +
          value[4] * 10 +
          value[5] * 3 +
          value[6] * 5 +
          value[7] * 9 +
          value[8] * 4 +
          value[9] * 6 +
          value[10] * 8) %
          11) %
        10;
      if (firstNumber != value[10] || secondNumber != value[11]) {
        return false;
      }
      return true;
    };
  },
};
Vue.use(checkInnPlugin);

/* Websanova */
Vue.use(Auth, {
  auth: AuthBasic,
  http: HttpVueResource,
  router: RouterVueRouter,
  rolesKey: "role",
  tokenDefaultKey: "auth_token",
  stores: ["storage"],
  loginData: {
    url: process.env.VUE_APP_API + "/auth/login",
  },
  parseUserData: function (response) {
    store.commit("setUser", response.data);
    window.ym(71025235, "userParams", {
      ClientID: response.data.id,
    });
    return response.data;
  },
});

if (document.getElementById("app")) {
  new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}
if (document.getElementById("auth")) {
  new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(Widget),
  }).$mount("#auth");
}
if (document.getElementById("okved")) {
  new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(Okved),
  }).$mount("#okved");
}
