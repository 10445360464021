<template>
  <v-form ref="mainForm" lazy-validation>
    <v-radio-group v-model="sign.status" mandatory>
      <v-row>
        <v-col class="col-12 align-self-end">
          <p class="body-1 text--black">
            Для электронной подачи нужна квалифицированная электронная подпись.
            Ее можно бесплатно выпустить в банке-партнере или через Dokia.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 align-self-end mb-4">
          <v-radio value="1" class="mb-1">
            <template v-slot:label>
              <p class="text-uppercase subtitle-2 text-break mb-0 text--black">
                ВЫПУСТИТЬ ЭЦП В БАНКЕ-ПАРТНЕРЕ — бесплатно
              </p>
            </template>
          </v-radio>
          <p class="body-2 ml-8 text--black mb-1">
            Бесплатное открытие счета и специальные тарифы. Нужно только выбрать
            банк:
          </p>
          <v-chip-group
            v-if="sign.status == 1"
            v-model="sign.offer"
            class="ml-8"
            active-class="accent text--primary"
            :column="this.$vuetify.breakpoint.name !== 'xs'"
          >
            <v-chip
              v-for="offer in offers"
              :key="offer.id"
              :value="offer.id"
              class="my-0 my-sm-1"
            >
              <v-icon v-if="sign.offer == offer.id" small class="mr-2">
                mdi-check
              </v-icon>
              {{ offer.title }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 align-self-end">
          <v-radio value="2" class="mb-1">
            <template v-slot:label>
              <p class="text-uppercase subtitle-2 text-break mb-0 text--black">
                ВЫПУСТИТЬ ЭЦП ЧЕРЕЗ Dokia — 2690 ₽
              </p>
            </template>
          </v-radio>
          <p class="body-2 ml-8 text--black mb-0">
            Защитим номер от спама и проверим документы на ошибки
          </p>
        </v-col>
      </v-row>
    </v-radio-group>
    <template v-if="sign.offer !== null && sign.status == 1">
      <v-row class="mt-2">
        <v-col class="col-12 align-self-end">
          <p class="text-h6 text--black">Загрузка документов</p>
          <p class="body-1 mb-1 text--black">
            Для выпуска ЭЦП необходима предварительная идентификация вашей
            личности. Для этого загрузите четкие фотографии или скан-копии
            паспорта:
          </p>
          <v-row class="mt-2">
            <v-col class="col-2">
              <v-img src="@/assets/img/passport_main.svg" contain />
            </v-col>
            <v-col class="col-10"
              >Разворот с основной информацией
              <br />
              <a
                @click="passport_main = true"
                class="text-decoration-underline blue--text"
                >Посмотреть образец</a
              >
              <v-dialog v-model="passport_main" width="auto">
                <v-card class="mx-auto pa-3" width="450px">
                  <v-img src="@/assets/img/p_main.jpg" width="450px"></v-img>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-2">
              <v-img src="@/assets/img/passport_reg.svg" contain />
            </v-col>
            <v-col class="col-10"
              >Разворот с актуальным штампом регистрации<br />
              <a
                @click="passport_reg = true"
                class="text-decoration-underline blue--text"
                >Посмотреть образец</a
              >
              <v-dialog v-model="passport_reg" width="auto">
                <v-card class="mx-auto pa-3" width="600px">
                  <v-img src="@/assets/img/p_reg.jpg" width="600px"></v-img>
                </v-card> </v-dialog
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 col-md-6 align-self-end">
          <dragfile
            :title="'Разврорт с основной информацией'"
            :description="
              sign.files && sign.files.passport
                ? sign.files.passport
                : 'Перетащите файл в эту область'
            "
            :icon="
              sign.files && sign.files.passport
                ? 'mdi-cloud-check-outline'
                : 'mdi-cloud-upload-outline'
            "
            @success="fileUploaded($event, 'passport')"
          >
          </dragfile>
        </v-col>
        <v-col class="col-12 col-md-6 align-self-end">
          <dragfile
            :title="'Разворот с штампом регистрации'"
            :description="
              sign.files && sign.files.address
                ? sign.files.address
                : 'Перетащите файл в эту область'
            "
            :icon="
              sign.files && sign.files.address
                ? 'mdi-cloud-check-outline'
                : 'mdi-cloud-upload-outline'
            "
            @success="fileUploaded($event, 'address')"
          ></dragfile>
        </v-col>
      </v-row>
    </template>
    <v-alert v-if="errors.sign" dense outlined type="error" class="mt-2">
      {{ errors.sign }}
    </v-alert>
    <v-row class="mt-8 mt-sm-14">
      <v-col>
        <v-btn @click="back" color="secondary" class="text--primary">
          Назад
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="accent"
          class="text--primary"
          @click="next"
          :disabled="!isButtonActive"
        >
          Сохранить &rarr;
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Dragfile from "@/components/Dragfile.vue";

export default {
  components: {
    Dragfile,
  },
  data() {
    return {
      passport_main: false,
      passport_reg: false,
      sign: {
        status: null,
        offer: null,
        files: {
          passport: null,
          address: null,
        },
      },
      loading: false,
      offers: null,
      errors: {
        sign: null,
      },
    };
  },
  created() {
    if (localStorage.getItem("sign") && localStorage.getItem("sign") !== null) {
      this.sign = JSON.parse(localStorage.getItem("sign"));
    }
  },
  computed: {
    isButtonActive() {
      if (
        (this.sign.status == 1 &&
          this.sign.files &&
          this.sign.offer &&
          this.sign.files.passport &&
          this.sign.files.address) ||
        this.sign.status == 2
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    sign: {
      handler: function () {
        if (this.sign && this.sign.status == 2) {
          this.$store.commit("isFormValid", ["sign", false]);
        } else {
          this.saveContent();
        }
      },
      deep: true,
    },
  },
  mounted() {
    const params = new URLSearchParams();
    params.append("type", this.$store.state.type);
    this.axios
      .get(
        process.env.VUE_APP_API +
          "/sign/offers/" +
          this.$store.state.document.id,
        {
          params: params,
        }
      )
      .then((result) => {
        this.offers = result.data.offers;
        if (result.data.sign) {
          this.sign = JSON.parse(result.data.sign);
        }
        if (result.data.files) {
          this.sign.files = JSON.parse(result.data.files);
        }
      })
      .catch((error) => console.log("error", error));
    if (localStorage.getItem("sign")) {
      this.sign = JSON.parse(localStorage.getItem("sign"));
    }
    if (this.$store.state.formValidation) {
      var self = this;
      setTimeout(function () {
        if (!self.next(false)) {
          self.$store.commit("formValidation", false);
        }
      }, 0);
    }
  },
  methods: {
    back() {
      this.$emit("pushPrev");
    },
    next() {
      if (!this.validate(true)) {
        return false;
      }
      if (window.dataLayer !== undefined) {
        if (this.$store.state.type == "legal") {
          window.dataLayer.push({ event: "legalSignBank" });
        } else {
          window.dataLayer.push({ event: "ipSignBank" });
        }
      }

      var self = this;
      this.$store.commit("initStore", {
        type: self.$store.state.type,
        callback: function () {
          localStorage.setItem("sign", JSON.stringify(self.sign));
          self.$store.commit("updateSign", { sign: self.sign });
        },
      });
      this.$emit("pushNext");
      return true;
    },
    fileUploaded(file, key) {
      var self = this,
        filename = file.name,
        index = key;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("input", index);
      formData.append("id", this.$store.state.document.id);
      let options = {
        headers: { "Content-Type": "multipart/form-data" },
        mode: "cors",
      };
      this.axios
        .post(process.env.VUE_APP_API + "/sign/fileupload", formData, options)
        .then(function (result) {
          self.saveContent(filename, self.sign.files, index);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    validate(witherror = true) {
      var result = this.$refs.mainForm.validate() && this.isButtonActive;
      if (!witherror && !result) {
        this.$refs.mainForm.resetValidation();
      }
      if (witherror && this.sign.status == 2) {
        result = false;
        this.$set(
          this.errors,
          "sign",
          "В данный момент этот способ получения ЭЦП недоступен"
        );
        if (window.dataLayer !== undefined) {
          window.dataLayer.push({ event: "ipSignDokia" });
        }
      } else if (witherror && this.sign.status == 1 && !this.isButtonActive) {
        result = false;
        this.$set(this.errors, "sign", "Необходимо выбрать один из вариантов");
      }
      setTimeout(
        function () {
          this.$store.commit("isFormValid", ["sign", result]);
        }.bind(this),
        0
      );
      return result;
    },
    saveContent(e, obj, key) {
      this.$set(this.errors, "sign", null);
      if (obj == null && obj !== undefined) {
        obj = this;
      }
      if (e !== null && e !== undefined) {
        this.$set(obj, key, e);
      }
      const payload = this.sign;
      localStorage.setItem("sign", JSON.stringify(payload));
    },
  },
};
</script>
