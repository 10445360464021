<template>
  <v-form ref="mainForm">
    <v-alert
      dark
      type="warning"
      icon="mdi-bank"
      color="light_warning"
      elevation="1"
      class="text--black"
      v-if="method && method.status == 1"
    >
      <v-row align="center">
        <v-col class="grow">
          Для онлайн-регистрации выберите подходящий для вас банк-партнер
        </v-col>
      </v-row>
    </v-alert>
    <p class="text-body-2" v-else>
      Подобрали для вас лучшие тарифы от банков: обслуживание 0 ₽, открытие
      счета 0 ₽ — только для пользователей DOKIA.
    </p>
    <v-card
      v-for="offer in offers"
      v-show="offer.visible || showOffers"
      :key="offer.id"
      class="mb-3 mb-sm-5 pa-3 pa-sm-5 v-card_border"
      :class="{ active: id == offer.id }"
      @click="setActive(offer.id)"
    >
      <template v-if="method && method.status == 1">
        <v-card-title class="headline pt-0 px-0">
          <div
            class="v-card-title__inner d-flex align-start align-sm-center text-overline font-weight-black flex-column flex-sm-row"
          >
            <v-img
              position="left"
              max-height="45"
              :src="api_url + '/' + offer.img"
              contain
              class="col-3 order-0 order-sm-2 mb-4 mb-sm-0"
            />
          </div>
        </v-card-title>
        <v-card-text class="px-0 pb-0 pb-sm-3">
          <template v-if="id == offer.id">
            <template v-for="(field, index) in JSON.parse(offer.form)">
              <v-text-field
                :key="index"
                v-if="field.type == 'text' || field.type == 'tel'"
                :value="fields[field.id].text"
                @input="
                  debounceInput($event, 'saveContent', fields[field.id], 'text')
                "
                :label="field.label"
                filled
                background-color="field_bg"
                class="rounded-0 pb-8"
                hide-details="auto"
                v-mask="field.mask"
                :rules="[rules.required]"
              />
              <template v-else-if="field.type == 'file'">
                <p
                  class="text-subtitle-1 mb-2 ml-1 file-label"
                  v-if="field.label"
                  :key="index + '-label'"
                  v-html="field.label"
                ></p>
                <v-row
                  :key="index"
                  class="mb-3 mb-sm-0 flex-column flex-sm-row"
                >
                  <v-col class="col-10">
                    <v-file-input
                      class="pt-0"
                      v-model="fields[field.id].file"
                      accept=".jpg,.jpeg,.bmp,.png"
                      :label="field.description"
                      prepend-icon="mdi-paperclip"
                      :id="field.name"
                      @change="sendFile($event, field.id)"
                      :rules="[rules.required]"
                    ></v-file-input>
                  </v-col>
                  <v-col>
                    <v-btn color="primary" @click="addFile(field.name)">
                      Обзор
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </template>
          </template>
          <template v-else>
            <p class="text-subtitle-1">
              Для продолжения регистрации вам потребуется:
            </p>
            <v-row class="mb-3 mb-sm-0 flex-column flex-sm-row">
              <v-col
                v-for="(field, index) in JSON.parse(offer.form)"
                :key="index"
                class="py-0 py-sm-3"
              >
                <span class="text--black"
                  >{{ parseInt(index) + 1 }}. {{ field.description }}</span
                >
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-title class="headline pt-0 px-0">
          <div
            class="v-card-title__inner d-flex align-start align-sm-center text-overline font-weight-black flex-column flex-sm-row"
          >
            <span class="order-3 order-sm-0">{{ offer.tariff }}</span>
            <v-spacer />
            <v-img
              position="right"
              max-height="45"
              :src="api_url + '/' + offer.img"
              contain
              class="col-3 order-0 order-sm-2 mb-4 mb-sm-0"
            />
          </div>
        </v-card-title>
        <v-card-text class="px-0 pb-0 pb-sm-3">
          <v-row class="mb-3 mb-sm-0 flex-column flex-sm-row">
            <v-col
              v-for="(benefit, index) in JSON.parse(offer.benefits).benefits"
              :key="index"
              class="py-0 py-sm-3"
            >
              <span class="text--black">{{ benefit }}</span>
            </v-col>
          </v-row>
          <v-btn
            depressed
            :color="id == offer.id ? 'accent text--primary' : 'btn_gray'"
            small
            :elevation="id == offer.id ? 0 : 2"
            class="text--primary"
            @click="saveContent(offer.id, null, 'id')"
          >
            <v-icon v-if="id == offer.id" small class="mr-2">
              mdi-check
            </v-icon>
            {{ id == offer.id ? "Выбран" : "Выбрать" }}
          </v-btn>
        </v-card-text>
      </template>
    </v-card>

    <v-card
      v-show="showOffers && !hidebyself"
      class="mb-3 mb-sm-5 pa-3 v-card_border"
    >
      <v-card-text class="text--black pa-0 px-sm-2 py-sm-4">
        <span
          >Отказаться от защиты номера, компенсации государственной пошлины и
          выбрать банк самостоятельно</span
        >
        <v-card-actions class="px-0 pb-0 pt-2 pt-sm-4">
          <v-btn
            depressed
            :color="id == 0 ? 'accent text--primary' : 'btn_gray'"
            small
            :elevation="id == 0 ? 0 : 2"
            class="text--primary"
            @click="saveContent(0, null, 'id')"
          >
            <v-icon v-if="id == 0" small class="mr-2"> mdi-check</v-icon>
            {{ id == 0 ? "Выбран" : "Выбрать" }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>

    <v-btn
      v-show="!showOffers"
      block
      text
      class="font-weight-bold"
      color="primary"
      @click="toggleOffers"
    >
      Показать все предложения
    </v-btn>
    <v-alert v-if="errors.id" dense outlined type="error" class="mt-4">
      {{ errors.id }}
    </v-alert>

    <v-row class="mt-8 mt-sm-14">
      <v-col>
        <v-btn color="secondary" class="text--primary" @click="back()">
          Назад
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="accent"
          class="text--primary"
          @click="next"
          :disabled="!$store.state.isFormValid.bank"
        >
          Сохранить &rarr;
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      api_url: process.env.VUE_APP_URL,
      id: null,
      loading: false,
      offers: [],
      fields: {},
      showOffers: false,
      hidebyself: false,
      method: null,
      errors: {
        bank: null,
      },
      rules: {
        required: (v) => !!v || "Это поле обязательно для заполнения",
        // field(mask, text) {
        //   return v => (!v || new RegExp(mask,"g").test(v)) || text;
        // }
      },
    };
  },
  watch: {
    id() {
      this.preValid();
    },
  },
  created() {
    if (localStorage.getItem("bank")) {
      var bank = JSON.parse(localStorage.getItem("bank"));
      this.id = bank.id;
      if (bank.fields) {
        this.fields = bank.fields;
      }
      // Если есть названия файлов в localstore, то делаем фиктивные файлы в полях
      for (var field in this.fields) {
        if (this.fields[field].filename) {
          this.fields[field].file = new File(
            [""],
            this.fields[field].filename,
            { type: "image/jpg" }
          );
        }
      }
    }
    var self = this;
    this.method = JSON.parse(localStorage.getItem("method"));
    if (this.method && this.method.status == 1) {
      this.hidebyself = true;
    }
    let options = {
      method: "get",
      headers: { "Content-Type": "application/json" },
      url:
        process.env.VUE_APP_API +
        "/offers/legal/" +
        (this.method && this.method.status == 1 ? "online" : "all"),
      mode: "cors",
    };
    this.axios(options)
      .then((result) => {
        self.offers = result.data;
        if (self.id) {
          var offer = self.offers.find((obj) => {
            return obj.id == self.id;
          });
          if (!offer) {
            self.id = null;
            localStorage.removeItem("bank");
            this.$store.commit("updateBank", { bank: null });
            this.$store.commit("isFormValid", ["bank", false]);
            this.$store.commit("isFormValid", ["document", false]);
            this.$store.commit("isFormValid", ["documentOnline", false]);
          } else {
            var form = [];
            this.offers.forEach(function (item) {
              if (item.id == self.id) {
                form = JSON.parse(item.form);
              }
            });
            if (form) {
              form.forEach(function (item) {
                if (!self.fields[item.id]) {
                  self.$set(self.fields, item.id, {
                    text: null,
                    filename: null,
                    file: null,
                  });
                }
              });
            }
          }
        }
        if (self.offers.length > 0) {
          if (self.id === 0 || (offer !== undefined && offer.visible === 0)) {
            self.toggleOffers();
          }
        } else if (self.offers.length <= 3) {
          self.toggleOffers();
        }
        if (self.$store.state.formValidation) {
          setTimeout(function () {
            if (!localStorage.getItem("bank") || !self.next(false)) {
              self.$store.commit("formValidation", false);
            }
          }, 0);
        }
      })
      .catch((error) => console.log("error", error));
  },
  methods: {
    toggleOffers() {
      this.showOffers = !this.showOffers;
    },
    back() {
      this.$emit("pushPrev");
      if (this.id) {
        this.$store.commit("updateBank", {
          bank: { id: this.id, fields: this.fields },
        });
      }
      this.saveContent();
    },
    next() {
      if (!this.validate(true)) {
        return false;
      }
      this.loading = true;
      if (window.dataLayer !== undefined) {
        if (this.$route.matched[0].name == "Legal") {
          window.dataLayer.push({ event: "bankSent", secEvent: this.bank });
        } else if (this.$route.matched[0].name == "Individual") {
          window.dataLayer.push({ event: "ipBankSent", secEvent: this.bank });
        }
      }
      this.$store.commit("updateBank", {
        bank: { id: this.id, fields: this.fields },
      });
      this.$store.commit("isFormValid", ["document", true]);
      this.$store.commit("isFormValid", ["documentOnline", true]);
      this.saveContent();
      this.$emit("pushNext");
      this.loading = false;
      return true;
    },
    setActive(id) {
      var self = this;
      this.id = id;
      var form = [];
      this.offers.forEach(function (item) {
        if (item.id == self.id) {
          form = JSON.parse(item.form);
        }
      });
      if (form) {
        form.forEach(function (item) {
          if (!self.fields[item.id]) {
            self.$set(self.fields, item.id, {
              text: null,
              filename: null,
              file: null,
            });
          }
        });
      }
      this.saveContent();
    },
    preValid() {
      if (this.id !== null) {
        this.$store.commit("isFormValid", ["bank", true]);
      } else {
        this.$store.commit("isFormValid", ["bank", false]);
      }
    },
    validate(witherror = true) {
      var result = this.$refs.mainForm.validate();
      if (this.id === null || this.id === undefined) {
        result = false;
        Vue.set(this.errors, "id", "Необходимо выбрать один из вариантов");
      }
      if (!witherror && !result) {
        this.$refs.mainForm.resetValidation();
      }
      setTimeout(
        function () {
          this.$store.commit("isFormValid", ["id", result]);
        }.bind(this),
        0
      );
      return result;
    },
    addFile(filename) {
      document.getElementById(filename).click();
    },
    sendFile(event, id) {
      var self = this;
      if (event) {
        let formData = new FormData();
        formData.append("file", this.fields[id].file);
        formData.append("input", id);
        formData.append("id", this.$store.state.document.id);
        let options = {
          headers: { "Content-Type": "multipart/form-data" },
          mode: "cors",
        };
        this.axios
          .post(process.env.VUE_APP_API + "/bank/fileupload", formData, options)
          .then(function (result) {
            self.saveContent(
              self.fields[id].file.name,
              self.fields[id],
              "filename"
            );
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        self.$set(self.fields, id, { filename: null, file: null });
      }
    },
    saveContent(e, obj, key) {
      if (obj == null && obj !== undefined) {
        obj = this;
      }
      if (e !== null && e !== undefined) {
        this.$set(obj, key, e);
      }
      const payload = { id: this.id, fields: this.fields };
      localStorage.setItem("bank", JSON.stringify(payload));
    },
  },
};
</script>
