import Main from "@/components/Main.vue";
import Documents from "@/pages/main/Documents.vue";
import Legal from "@/components/Legal.vue";
import Individual from "@/components/Individual.vue";
import Appdoc from "@/pages/individual/Appdoc.vue";
import Name from "@/pages/Name.vue";
import Founders from "@/pages/Founders.vue";
import Activities from "@/pages/Activities.vue";
import Taxes from "@/pages/Taxes.vue";
import Сharter from "@/pages/Charter.vue";
import Method from "@/pages/Method.vue";
import Sign from "@/pages/Sign.vue";
import individualMethod from "@/pages/individual/Method.vue";
import individualTaxes from "@/pages/individual/Taxes.vue";
import Bank from "@/pages/Bank.vue";
import Document from "@/pages/Document.vue";
import DocumentOnline from "@/pages/DocumentOnline.vue";

const routes = [
  {
    path: "/",
    redirect: "/main/documents",
  },
  {
    path: "/main",
    redirect: "/main/documents",
    name: "Main",
    component: Main,
    children: [
      {
        path: "documents",
        name: "Documents",
        component: Documents,
      },
    ],
  },
  {
    path: "/legal",
    redirect: "/legal/activities",
    name: "Legal",
    component: Legal,
    children: [
      {
        path: "activities",
        name: "activities",
        component: Activities,
        meta: { disabled: false },
      },
      {
        path: "founders",
        name: "founders",
        component: Founders,
        meta: { disabled: false },
      },
      {
        path: "name",
        name: "name",
        component: Name,
        meta: { disabled: false },
      },
      {
        path: "taxes",
        name: "taxes",
        component: Taxes,
        meta: { disabled: false },
      },
      {
        path: "charter",
        name: "charter",
        component: Сharter,
        meta: { disabled: false },
      },
      {
        path: "method",
        name: "method",
        component: Method,
        meta: { disabled: false },
      },
      {
        path: "bank",
        name: "bank",
        component: Bank,
        meta: { disabled: false },
      },
      {
        path: "sign",
        name: "sign",
        component: Sign,
        meta: { disabled: true },
      },
      {
        path: "document",
        name: "document",
        component: Document,
        meta: { disabled: false },
      },
      {
        path: "document-online",
        name: "documentOnline",
        component: DocumentOnline,
        meta: { disabled: true },
      },
    ],
  },
  {
    path: "/individual",
    redirect: "/individual/activities",
    name: "Individual",
    component: Individual,
    children: [
      {
        path: "activities",
        name: "activities",
        component: Activities,
        meta: { disabled: false },
      },
      {
        path: "method",
        name: "method",
        component: individualMethod,
        meta: { disabled: false },
      },
      {
        path: "sign",
        name: "sign",
        component: Sign,
        meta: { disabled: true },
      },
      {
        path: "appdoc",
        name: "appdoc",
        component: Appdoc,
        meta: { disabled: false },
      },
      {
        path: "taxes",
        name: "taxes",
        component: individualTaxes,
        meta: { disabled: false },
      },
      {
        path: "bank",
        name: "bank",
        component: Bank,
        meta: { disabled: false },
      },
      {
        path: "document",
        name: "document",
        component: Document,
        meta: { disabled: false },
      },
      {
        path: "document-online",
        name: "documentOnline",
        component: DocumentOnline,
        meta: { disabled: true },
      },
    ],
  },
];

export default routes;
