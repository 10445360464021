<template>
  <div>
    <p v-if="formatedDocs.length <= 0" class="ma-4">
      У вас нет готовых документов или черновиков. Хотите создать новый пакет
      документов?
    </p>
    <v-list>
      <v-list-item v-for="doc in formatedDocs" :key="doc.id">
        <v-list-item-content>
          <v-list-item-title v-text="doc.title"></v-list-item-title>
          <v-list-item-subtitle v-text="doc.updated_at"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <div class="text-center">
            <v-btn
              v-if="doc.file"
              depressed
              small
              :href="doc.file"
              @click="download(doc.type)"
            >
              Скачать
            </v-btn>
            <v-btn icon @click="edit(doc.id)">
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="deletingDoc = JSON.parse(JSON.stringify(doc))">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-dialog v-model="dialogDelete" persistent max-width="480">
      <v-card>
        <v-card-title class="headline">
          <span class="text-break text-h6"
            >Документ <b>{{ deletingDoc ? deletingDoc.title : "" }}</b> будет
            безвозвратно удалён</span
          >
        </v-card-title>
        <v-card-actions v-if="response.message" class="d-block">
          <v-alert :type="response.status ? response.status : ''">
            {{ response.message ? response.message : "" }}
          </v-alert>
        </v-card-actions>
        <v-card-actions v-if="!response.message">
          <v-btn color="green darken-1" text @click="deletingDoc = null">
            Отмена
          </v-btn>
          <v-spacer />
          <v-btn color="red" text @click="removeDoc(deletingDoc.id)">
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      response: {
        status: null,
        message: null,
      },
      deletingDoc: null,
      editingDoc: null,
      docs: null,
    };
  },
  computed: {
    formatedDocs() {
      if (!this.docs || this.docs.length === 0) return [];
      return this.docs.map((doc) => {
        return {
          id: doc.id,
          updated_at: new Date(doc.updated_at).toLocaleDateString("ru-RU"),
          title:
            doc.type == "legal"
              ? doc.title
                ? doc.title + ", ООО"
                : "ООО без названия"
              : doc.title
              ? doc.title + ", ИП"
              : "ИП без названия",
          file: doc.file,
          type: doc.type,
        };
      });
    },
    dialogDelete() {
      return this.deletingDoc ? true : false;
    },
    localTitle() {
      return localStorage.getItem("name")
        ? "«" + JSON.parse(localStorage.getItem("name")).title + "»"
        : null;
    },
  },
  watch: {
    "$store.state.islogin"(status) {
      if (status === true) {
        this.getDocuments();
      } else {
        this.docs = null;
      }
    },
  },
  mounted() {
    if (this.$auth.check()) {
      this.getDocuments();
    }
    if (window.dataLayer !== undefined) {
      window.dataLayer.push({ event: "pageMyDocs" });
    }
  },
  methods: {
    edit(id) {
      var self = this;
      let options = {
        method: "get",
        url: process.env.VUE_APP_API + "/documents/get?id=" + id,
        mode: "cors",
      };
      this.axios(options)
        .then((result) => {
          localStorage.removeItem("id");
          localStorage.removeItem("type");
          localStorage.removeItem("appdoc");
          localStorage.removeItem("activities");
          localStorage.removeItem("bank");
          localStorage.removeItem("name");
          localStorage.removeItem("sign");
          localStorage.removeItem("taxes");
          localStorage.removeItem("charter");
          localStorage.removeItem("method");
          localStorage.removeItem("founders");
          localStorage.setItem("edit", 1);
          for (let i in this.$store.state.isFormValid) {
            this.$store.state.isFormValid[i] = false;
          }
          if (result.data.id) {
            localStorage.setItem("id", result.data.id);
          }
          if (result.data.type) {
            localStorage.setItem("type", result.data.type);
          }
          if (
            result.data.title &&
            result.data.need_to_rent !== null &&
            result.data.address
          ) {
            localStorage.setItem(
              "name",
              JSON.stringify({
                title: result.data.title,
                need_to_rent: result.data.need_to_rent,
                address: JSON.parse(result.data.address),
                paths: result.data.address_paths
                  ? JSON.parse(result.data.address_paths)
                  : [],
              })
            );
          }
          if (result.data.appdoc) {
            localStorage.setItem("appdoc", result.data.appdoc);
          }
          if (result.data.activities) {
            localStorage.setItem("activities", result.data.activities);
          }
          if (result.data.address) {
            localStorage.setItem("address", result.data.address);
          }
          if (result.data.sign) {
            localStorage.setItem("sign", result.data.sign);
          }
          if (result.data.applicant) {
            localStorage.setItem("applicant", result.data.applicant);
          }
          if (result.data.charter) {
            localStorage.setItem("charter", result.data.charter);
          }
          if (result.data.method) {
            localStorage.setItem(
              "method",
              JSON.stringify({ status: result.data.method })
            );
          }
          let bank = {};
          if (result.data.bank_offer_id !== null) {
            bank = { id: result.data.bank_offer_id, fields: {} };
            localStorage.setItem("bank", JSON.stringify(bank));
          }
          if (result.data.bankfields !== null) {
            bank.fields = JSON.parse(result.data.bankfields);
            localStorage.setItem("bank", JSON.stringify(bank));
          }
          if (
            result.data.founders &&
            !!result.data.capital &&
            !!result.data.head
          ) {
            localStorage.setItem(
              "founders",
              JSON.stringify({
                capital: result.data.capital,
                founders: JSON.parse(result.data.founders),
                head: JSON.parse(result.data.head),
              })
            );
          }
          if (result.data.head) {
            localStorage.setItem("head", result.data.head);
          }
          if (result.data.taxes) {
            localStorage.setItem("taxes", result.data.taxes);
          }
          if (
            !result.data.title ||
            result.data.need_to_rent === null ||
            (!result.data.address && result.data.type == "legal") ||
            !result.data.activities ||
            !result.data.applicant ||
            result.data.bank_offer_id === null ||
            (!result.data.founders && result.data.type == "legal") ||
            (!result.data.capital && result.data.type == "legal") ||
            (!result.data.head && result.data.type == "legal") ||
            !result.data.taxes ||
            !result.data.sign ||
            (!result.data.charter && result.data.type == "legal") ||
            (!result.data.activities && result.data.type == "legal")
          ) {
            localStorage.setItem("edit", 2);
          }
          self.$router.push({
            name: result.data.type == "legal" ? "Legal" : "Individual",
          });
          window.ym(71025235, "userParams", {
            type: result.data.type,
            document: result.data.id,
          });
        })
        .catch((error) => console.log("error", error));
    },
    removeDoc(id) {
      var self = this;
      let options = {
        method: "post",
        url: process.env.VUE_APP_API + "/documents/remove",
        data: { id: id },
        mode: "cors",
      };
      this.axios(options)
        .then((response) => {
          self.response.message = response.data.message;
          self.response.status = response.data.status;
          for (var i = 0; i < self.formatedDocs.length; i++) {
            if (self.formatedDocs[i].id == id) {
              self.formatedDocs.splice(i, 1);
            }
          }
          setTimeout(function () {
            self.deletingDoc = null;
            self.response.status = null;
            self.response.message = null;
          }, 2000);
        })
        .catch((error) => {
          if (error.response) {
            self.response.message = error.response.data.message;
            self.response.status = error.response.data.status;
            setTimeout(function () {
              self.deletingDoc = null;
              self.response.status = null;
              self.response.message = null;
            }, 2000);
          }
        });
    },
    getDocuments() {
      var self = this;
      let options = {
        method: "get",
        url: process.env.VUE_APP_API + "/documents/getlist",
        mode: "cors",
      };
      this.axios(options)
        .then((result) => {
          self.docs = result.data;
        })
        .catch((error) => console.log("error", error));
    },
    download(type) {
      if (window.dataLayer !== undefined) {
        if (type == "legal") {
          window.dataLayer.push({ event: "docsDownload" });
        } else if (type == "individual") {
          window.dataLayer.push({ event: "ipDocsDownload" });
        }
      }
    },
  },
};
</script>
