import Vue from "vue";
import Vuex from "vuex";

const axios = require("axios");

Vue.use(Vuex);

const dataWatcher = (store) => {
  store.subscribe((mutation, state) => {
    let options;
    switch (mutation.type) {
      case "updateName":
        options = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: process.env.VUE_APP_API + "/name",
          mode: "cors",
          data: {
            id: state.document.id,
            title: state.document.title,
            short: state.document.short,
            address: state.document.address,
            need_to_rent: state.document.need_to_rent,
            paths: state.document.paths,
          },
        };
        axios(options)
          .then(function (result) {
            checkVersion(result, state);
          })
          .catch(function (error) {
            console.log(error);
          });
        break;
      case "updateActivities":
        options = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: process.env.VUE_APP_API + "/activities",
          mode: "cors",
          data: {
            id: state.document.id,
            activities: state.document.activities,
          },
        };
        axios(options)
          .then(function (result) {
            checkVersion(result, state);
          })
          .catch(function (error) {
            console.log(error);
          });
        break;
      case "updateTaxes":
        options = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: process.env.VUE_APP_API + "/taxes",
          mode: "cors",
          data: { id: state.document.id, taxes: state.document.taxes },
        };
        axios(options).then(function (result) {
          checkVersion(result, state);
        });
        break;
      case "updateCharter":
        options = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: process.env.VUE_APP_API + "/charter",
          mode: "cors",
          data: { id: state.document.id, charter: state.document.charter },
        };
        axios(options).then(function (result) {
          checkVersion(result, state);
        });
        break;
      case "updateMethod":
        options = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: process.env.VUE_APP_API + "/method",
          mode: "cors",
          data: { id: state.document.id, method: state.document.method },
        };
        axios(options).then(function (result) {
          checkVersion(result, state);
        });
        break;
      case "updateBank":
        options = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: process.env.VUE_APP_API + "/bank",
          mode: "cors",
          data: { id: state.document.id, bank: state.document.bank },
        };
        axios(options).then(function (result) {
          checkVersion(result, state);
        });
        break;
      case "updateFounders":
        options = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: process.env.VUE_APP_API + "/founders",
          mode: "cors",
          data: {
            id: state.document.id,
            capital: state.document.capital,
            founders: state.document.founders,
            head: state.document.head,
          },
        };
        axios(options).then(function (result) {
          checkVersion(result, state);
        });
        break;
      case "updateSign":
        options = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: process.env.VUE_APP_API + "/sign",
          mode: "cors",
          data: {
            id: state.document.id,
            sign: state.document.sign,
          },
        };
        axios(options).then(function (result) {
          checkVersion(result, state);
        });
        break;
      case "updateAppdoc":
        options = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: process.env.VUE_APP_API + "/appdoc",
          mode: "cors",
          data: {
            id: state.document.id,
            appdoc: state.document.appdoc,
          },
        };
        axios(options)
          .then(function (result) {
            checkVersion(result, state);
          })
          .catch(function (error) {
            console.log(error);
          });
        break;
    }
  });
};

export const store = new Vuex.Store({
  plugins: [dataWatcher],
  state: {
    version: null,
    document: {},
    type: null,
    isFormValid: {
      activities: false,
      appdoc: false,
      founders: false,
      sign: false,
      name: false,
      taxes: false,
      charter: false,
      method: false,
      bank: false,
      document: false,
      documentOnline: false,
    },
    steps: {
      legal: [
        {
          title: "Виды деятельности",
          link: "/legal/activities",
          name: "activities",
          disabled: false,
        },
        {
          title: "Учредители и руководство",
          link: "/legal/founders",
          name: "founders",
          disabled: false,
        },
        {
          title: "Наименование и адрес",
          link: "/legal/name",
          name: "name",
          disabled: false,
        },
        {
          title: "Налогообложение",
          link: "/legal/taxes",
          name: "taxes",
          disabled: false,
        },
        {
          title: "Выбор устава",
          link: "/legal/charter",
          name: "charter",
          disabled: false,
        },
        {
          title: "Способ регистрации",
          link: "/legal/method",
          name: "method",
          disabled: false,
        },
        {
          title: "Выбор банка",
          link: "/legal/bank",
          name: "bank",
          disabled: false,
        },
        {
          title: "Выпуск ЭЦП",
          link: "/legal/sign",
          name: "sign",
          disabled: true,
        },
        {
          title: "Получение документов",
          link: "/legal/document",
          name: "document",
          disabled: false,
        },
        {
          title: "Контактные данные",
          link: "/legal/document-online",
          name: "documentOnline",
          disabled: true,
        },
      ],
      individual: [
        {
          title: "Виды деятельности",
          link: "/individual/activities",
          name: "activities",
          disabled: false,
        },
        {
          title: "Способ регистрации",
          link: "/individual/method",
          name: "method",
          disabled: false,
        },
        {
          title: "Выпуск ЭЦП",
          link: "/individual/sign",
          name: "sign",
          disabled: true,
        },
        {
          title: "Данные заявителя",
          link: "/individual/appdoc",
          name: "appdoc",
          disabled: false,
        },
        {
          title: "Налогообложение",
          link: "/individual/taxes",
          name: "taxes",
          disabled: false,
        },
        {
          title: "Выбор банка",
          link: "/individual/bank",
          name: "bank",
          disabled: false,
        },
        {
          title: "Получение документов",
          link: "/individual/document",
          name: "document",
          disabled: false,
        },
        {
          title: "Контактные данные",
          link: "/individual/document-online",
          name: "documentOnline",
          disabled: true,
        },
      ],
    },
    formValidation: false,
    islogin: false,
    phone: null,
    email: null,
    id: null,
    loginPopup: false,
  },
  // actions: {
  //   getKey({ commit }) {
  //     axios
  //       .get(process.env.VUE_APP_API + '/init')
  //       .then(r => r.data)
  //       .then(key => {
  //         commit('updateKey', key)
  //       })
  //   },
  // },
  mutations: {
    setUser(state, payload) {
      state.islogin = true;
      state.phone = payload.phone;
      state.email = payload.email;
      state.id = payload.id;
    },
    unsetUser(state) {
      state.islogin = false;
      state.phone = null;
      state.email = null;
    },
    clearStore(state) {
      state.document = {};
      localStorage.removeItem("id");
      localStorage.removeItem("type");
      localStorage.removeItem("appdoc");
      localStorage.removeItem("activities");
      localStorage.removeItem("bank");
      localStorage.removeItem("name");
      localStorage.removeItem("taxes");
      localStorage.removeItem("charter");
      localStorage.removeItem("method");
      localStorage.removeItem("founders");
      localStorage.removeItem("sign");
      localStorage.removeItem("address");
    },
    initStore(state, payload) {
      if (localStorage.getItem("id")) {
        state.document.id = localStorage.getItem("id");
        if (payload.callback) {
          payload.callback();
        }
      } else {
        let options = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: process.env.VUE_APP_API + "/init",
          mode: "cors",
          data: { type: payload.type },
        };
        axios(options)
          .then((result) => {
            localStorage.setItem("id", result.data.id);
            localStorage.setItem("type", payload.type);
            state.document.id = result.data.id;
            if (payload.callback) {
              payload.callback();
            }
            window.ym(71025235, "userParams", {
              type: payload.type,
              document: result.data.id,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    setType(state, payload) {
      state.type = payload;
    },
    formValidation(state, status) {
      state.formValidation = status;
    },
    isFormValid(state, status) {
      if (typeof status === "boolean") {
        for (var key in state.isFormValid) {
          state.isFormValid[key] = status;
        }
      } else {
        state.isFormValid[status[0]] = status[1];
      }
    },
    updateId(state, id) {
      state.document.id = id;
    },
    updateSign(state, payload) {
      state.document.sign = payload.sign;
    },
    updateFounders(state, payload) {
      state.document.capital = payload.capital;
      state.document.founders = payload.founders;
      state.document.head = payload.head;
    },
    updateAppdoc(state, payload) {
      state.document.appdoc = payload;
    },
    // updateDocument(state, document) {
    //   state.document = document;
    // },
    updateName(state, payload) {
      state.document.title = payload.title;
      state.document.short = payload.short;
      state.document.need_to_rent = payload.need_to_rent;
      state.document.address = payload.address;
      state.document.paths = payload.paths;
    },
    updateActivities(state, payload) {
      state.document.activities = payload.activities;
    },
    updateTaxes(state, payload) {
      state.document.taxes = payload.taxes;
    },
    updateCharter(state, payload) {
      state.document.charter = payload.charter;
    },
    updateMethod(state, payload) {
      state.document.method = payload.method;
      if (payload.callback) {
        payload.callback();
      }
    },
    updateBank(state, payload) {
      state.document.bank = payload.bank;
    },
    updateDocument(state, payload) {
      state.document.title = payload.title;
      state.document.need_to_rent = payload.need_to_rent;
      state.document.address = JSON.parse(payload.address);
      state.document.sign = JSON.parse(payload.sign);
      state.document.aapplicant = JSON.parse(payload.applicant);
      state.document.bank_offer = payload.bank_offer;
      state.document.capital = payload.capital;
      state.document.founders = JSON.parse(payload.founders);
      state.document.head = JSON.parse(payload.head);
      state.document.okved = JSON.parse(payload.okved);
    },
    toggleFounders(state, payload) {
      if (
        payload.founders.length <= 1 &&
        payload.founders[0].id == payload.head.id
      ) {
        if (
          state.steps.legal[5].disabled &&
          state.document.method == undefined
        ) {
          state.isFormValid.bank = false;
          state.isFormValid.document = false;
        }
        state.isFormValid.method = false;
        state.steps.legal[5].disabled = false;
      } else {
        state.isFormValid.method = true;
        state.steps.legal[5].disabled = true;
      }
    },
  },
  getters: {
    NAME: (state) => {
      return {
        title: state.document.title,
        need_to_rent: state.document.need_to_rent,
        address: state.document.address,
        paths: state.document.paths,
      };
    },
    ID: (state) => {
      return { id: state.document.id };
    },
  },
  watch: {
    document: {
      handler: function (val) {
        console.log("changed!", val);
      },
      deep: true,
    },
  },
});

function checkVersion(result, state) {
  if (state.version == null) {
    state.version = result.headers["x-client-version"];
  } else if (state.version !== result.headers["x-client-version"]) {
    window.location.reload(true);
  }
}
