<template>
  <v-form>
    <p class="text-body-1">Налогообложение</p>
    <v-radio-group mandatory v-model="taxes.main">
      <v-row>
        <v-col class="col-12 align-self-end mb-4">
          <v-radio value="1" class="mb-1">
            <template v-slot:label>
              <p class="text-uppercase subtitle-2 text-break mb-0 text--black">
                Упрощенная система
              </p>
            </template>
          </v-radio>
          <p class="body-2 ml-8 mb-1 text-gray">
            Рекомендуем начать с упрощенной системой налогообложения:
            минимальная отчетность, минимальные налоги, легкая бухгалтерская
            отчетность
          </p>
          <v-chip-group
            v-if="taxes.main == 1"
            v-model="taxes.sub"
            class="ml-8"
            active-class="accent text--primary"
            :column="this.$vuetify.breakpoint.name !== 'xs'"
          >
            <v-chip
              value="1"
              class="my-0 my-sm-1"
              :class="{ 'accent text--primary': taxes.sub == 1 }"
            >
              <v-icon v-if="taxes.sub == 1" small class="mr-2">
                mdi-check
              </v-icon>
              Доходы 6%
            </v-chip>
            <v-chip
              value="2"
              class="my-0 my-sm-1"
              :class="{ 'accent text--primary': taxes.sub == 2 }"
            >
              <v-icon v-if="taxes.sub == 2" small class="mr-2">
                mdi-check
              </v-icon>
              Доходы минус расходы 15%
            </v-chip>
          </v-chip-group>
          <p class="body-2 ml-8 mb-1 text-gray" v-if="taxes.sub == 1">
            Подойдет, если вы не планируете больших расходов и желаете
            максимально упростить налоговый учет: платите налоги только с
            оборота, не учитывая исходящие платежи
          </p>
          <p class="body-2 ml-8 mb-1 text-gray" v-if="taxes.sub == 2">
            Подходит, если ваши расходы будут составлять более 60% от доходов.
            Налог может быть уменьшен на суммы страховых взносов, уплаченных в
            ПФР и ФОМС за сотрудников
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 align-self-end">
          <v-radio value="2" class="mb-1">
            <template v-slot:label>
              <p class="text-uppercase subtitle-2 text-break mb-0 text--black">
                Общая система налогообложения
              </p>
            </template>
          </v-radio>
          <p class="body-2 ml-8 mb-0 text-gray">
            Подойдет, если вы работаете с крупными компаниями, которые тоже
            применяют этот режим. Для них важно, чтобы вы платили НДС – в этом
            случае они смогут возместить этот налог, который платят сами.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 align-self-end">
          <v-radio value="3" class="mb-1">
            <template v-slot:label>
              <p class="text-uppercase subtitle-2 text-break mb-0 text--black">
                Патентная система налогообложения
              </p>
            </template>
          </v-radio>
          <p class="body-2 ml-8 mb-0 text-gray">
            Переход на патент осуществляется после регистрации ИП
          </p>
        </v-col>
      </v-row>
    </v-radio-group>
    <v-alert v-if="errors.taxes" dense outlined type="error">
      {{ errors.taxes }}
    </v-alert>

    <v-row class="mt-8 mt-sm-14">
      <v-col>
        <v-btn color="secondary" class="text--primary" @click="back()">
          Назад
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="accent"
          class="text--primary"
          @click="next(true)"
          :disabled="!$store.state.isFormValid.taxes"
        >
          Сохранить &rarr;
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      taxes: {
        main: null,
        sub: null,
      },
      consultation: false,
      loading: false,
      errors: {
        taxes: null,
      },
    };
  },
  watch: {
    taxes: {
      handler: function (val) {
        if (val) {
          Vue.set(this.errors, "taxes", null);
        }
        this.preValid();
        localStorage.setItem("taxes", JSON.stringify(this.taxes));
      },
      deep: true,
    },
  },
  mounted() {
    if (localStorage.getItem("taxes")) {
      this.taxes = JSON.parse(localStorage.getItem("taxes"));
      if (this.taxes == 1) {
        this.taxes = {
          main: 1,
          sub: 1,
        };
      } else if (this.taxes == 2) {
        this.taxes = {
          main: 1,
          sub: 2,
        };
      } else if (this.taxes == 3) {
        this.taxes = {
          main: 2,
          sub: null,
        };
      }
    }
    if (localStorage.getItem("consultation")) {
      this.consultation = localStorage.getItem("consultation");
    }
    if (this.$store.state.formValidation) {
      var self = this;
      setTimeout(function () {
        if (!self.next(false)) {
          self.$store.commit("formValidation", false);
        }
      }, 0);
    }
  },
  methods: {
    back() {
      this.$emit("pushPrev");
    },
    next(witherror = true) {
      if (!this.validate(witherror) && !this.preValid()) {
        return false;
      }
      this.loading = true;
      if (window.dataLayer !== undefined) {
        window.dataLayer.push({ event: "ipTaxSent" });
      }
      localStorage.setItem("taxes", JSON.stringify(this.taxes));
      localStorage.setItem("consultation", this.consultation);
      this.$store.commit("updateTaxes", { taxes: this.taxes });
      this.$emit("pushNext");
      this.loading = false;
      return true;
    },
    preValid() {
      if (
        this.taxes.main == 2 ||
        this.taxes.main == 3 ||
        (this.taxes.main == 1 && this.taxes.sub)
      ) {
        this.$store.commit("isFormValid", ["taxes", true]);
        return true;
      } else {
        this.$store.commit("isFormValid", ["taxes", false]);
        return false;
      }
    },
    validate(witherror = true) {
      let result = true;
      if (!this.preValid()) {
        result = false;
      }
      if (witherror && !result) {
        if (!this.taxes) {
          Vue.set(
            this.errors,
            "taxes",
            "Необходимо выбрать систему налогообложения"
          );
        }
      }
      setTimeout(
        function () {
          this.$store.commit("isFormValid", ["taxes", result]);
        }.bind(this),
        0
      );
      return result;
    },
  },
};
</script>

<style scoped>
/* .recommend {
    background-color: #99e55e;
    border-radius: 3px;
    text-transform: uppercase;
    color: black;
    padding: 5px 10px;
    font-size: 11px;
  }
  .active {
    background-color: #ffdf39;
    border: 1px solid #000000;
    background-color: white;
  } */
</style>
