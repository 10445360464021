<template>
  <v-form ref="mainForm" lazy-validation>
    <v-row class="mb-7">
      <v-col class="col-12 align-self-end">
        <v-row>
          <v-col>
            <v-card
              v-ripple="{ class: `accent--text` }"
              class="v-card_border d-flex flex-column align-start v-card_marker"
              :class="{ active: method.status == 1 }"
              @click="setMethod(1)"
            >
              <span class="v-card__marker">Рекомендуем</span>
              <v-card-text class="pa-8">
                <v-card-title
                  class="subtitle-2 py-0 px-0 mb-4 text-uppercase text-break"
                  >онлайн Регистрация
                </v-card-title>
                <span class="d-block mb-3"
                  >С выпуском ЭЦП, проверкой документов и отправкой в ФНС в
                  электронном виде.</span
                >
                <div class="px-0 d-flex">
                  <span class="mr-4">
                    <v-icon size="18" class="black--text">mdi-check</v-icon>
                  </span>
                  <div class="pt-0 pb-3">
                    <span class="body-2"
                      >0 ₽ пошлина за электронную подачу</span
                    >
                  </div>
                </div>
                <div class="px-0 d-flex">
                  <span class="mr-4">
                    <v-icon size="18" class="black--text">mdi-check</v-icon>
                  </span>
                  <div class="pt-0 pb-3">
                    <span class="body-2">Без визита в ФНС</span>
                  </div>
                </div>
                <div class="px-0 d-flex">
                  <span class="mr-4">
                    <v-icon size="18" class="black--text">mdi-check</v-icon>
                  </span>
                  <div class="pt-0 pb-3">
                    <span class="body-2">Документы без ошибок</span>
                  </div>
                </div>
                <v-btn
                  :depressed="method.status == 1"
                  :color="
                    method.status == 1 ? 'accent text--primary' : 'btn_gray'
                  "
                  small
                  class="text--primary mt-8"
                >
                  <v-icon v-if="method.status == 1" small class="mr-2">
                    mdi-check
                  </v-icon>
                  {{ method.status == 1 ? "Выбран" : "Выбрать" }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              v-ripple="{ class: `accent--text` }"
              class="v-card_border d-flex flex-column align-start"
              :class="{ active: method.status == 2 }"
              @click="setMethod(2)"
            >
              <v-card-text class="pa-8">
                <v-card-title
                  class="subtitle-2 py-0 px-0 mb-4 text-uppercase text-break"
                  >Самостоятельно в ФНС
                </v-card-title>
                <span class="d-block mb-3"
                  >Сервис сформирует документы, которые надо будет распечатать и
                  отвезти в ФНС.</span
                >
                <div class="px-0 d-flex">
                  <span class="mr-4">
                    <v-icon size="18" class="black--text">mdi-check</v-icon>
                  </span>
                  <div class="pt-0 pb-3">
                    <span class="body-2">800 ₽ пошлина</span>
                  </div>
                </div>
                <div class="px-0 d-flex">
                  <span class="mr-4">
                    <v-icon size="18" class="black--text">mdi-check</v-icon>
                  </span>
                  <div class="pt-0 pb-3">
                    <span class="body-2">С посещением ФНС/МФЦ</span>
                  </div>
                </div>
                <div class="px-0 d-flex">
                  <span class="mr-4">
                    <v-icon size="18" class="black--text">mdi-check</v-icon>
                  </span>
                  <div class="pt-0 pb-3">
                    <span class="body-2">Документы без ошибок</span>
                  </div>
                </div>
                <v-btn
                  :depressed="method.status == 2"
                  :color="
                    method.status == 2 ? 'accent text--primary' : 'btn_gray'
                  "
                  small
                  class="text--primary mt-8"
                >
                  <v-icon v-if="method.status == 2" small class="mr-2">
                    mdi-check
                  </v-icon>
                  {{ method.status == 2 ? "Выбран" : "Выбрать" }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-alert v-if="errors.method" dense outlined type="error">
      {{ errors.method }}
    </v-alert>

    <v-row class="mt-8 mt-sm-14">
      <v-col>
        <v-btn @click="back" color="secondary" class="text--primary">
          Назад
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="accent"
          class="text--primary"
          @click="next"
          :disabled="!method.status"
        >
          Сохранить &rarr;
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      method: {
        status: null,
      },
      loading: false,
      errors: {
        method: null,
      },
    };
  },
  mounted() {
    if (localStorage.getItem("method")) {
      this.method = JSON.parse(localStorage.getItem("method"));
    }
    if (this.$store.state.formValidation) {
      var self = this;
      setTimeout(function () {
        if (!self.next(false)) {
          self.$store.commit("formValidation", false);
        }
      }, 0);
    }
    this.checkStepper();
  },
  methods: {
    back() {
      this.$emit("pushPrev");
    },
    setMethod(val) {
      this.$set(this.method, "status", val);
      if (val !== 1) {
        this.$set(this.method, "number", null);
      }
      this.$set(this.errors, "method", null);
      localStorage.setItem("method", JSON.stringify(this.method));
      this.$store.commit("updateMethod", { method: this.method.status });
      this.checkStepper();
      this.$store.commit("isFormValid", ["method", true]);
    },
    checkStepper() {
      if (this.method.status == 1) {
        Vue.set(
          this.$router.options.routes[3].children[2].meta,
          "disabled",
          false
        );
        Vue.set(
          this.$router.options.routes[3].children[6].meta,
          "disabled",
          true
        );
        Vue.set(
          this.$router.options.routes[3].children[7].meta,
          "disabled",
          false
        );
        Vue.set(
          this.$router.options.routes[2].children[5].meta,
          "disabled",
          true
        );
        Vue.set(this.$store.state.steps.individual[2], "disabled", false);
        Vue.set(this.$store.state.steps.individual[6], "disabled", true);
        Vue.set(this.$store.state.steps.individual[7], "disabled", false);
        Vue.set(this.$store.state.steps.individual[5], "disabled", true);
        this.$store.commit("isFormValid", [
          "documentOnline",
          this.$store.state.isFormValid.document,
        ]);
      } else {
        Vue.set(this.$store.state.steps.individual[2], "disabled", true);
        Vue.set(
          this.$router.options.routes[3].children[6].meta,
          "disabled",
          false
        );
        Vue.set(
          this.$router.options.routes[3].children[7].meta,
          "disabled",
          true
        );
        Vue.set(
          this.$router.options.routes[3].children[2].meta,
          "disabled",
          true
        );
        Vue.set(
          this.$router.options.routes[3].children[5].meta,
          "disabled",
          false
        );
        Vue.set(this.$store.state.steps.individual[6], "disabled", false);
        Vue.set(this.$store.state.steps.individual[7], "disabled", true);
        Vue.set(this.$store.state.steps.individual[5], "disabled", false);
        this.$store.commit("isFormValid", [
          "document",
          this.$store.state.isFormValid.documentOnline,
        ]);
      }
    },
    next() {
      if (!this.validate(true)) {
        return false;
      }
      if (this.method.status == 1) {
        if (window.dataLayer !== undefined) {
          window.dataLayer.push({ event: "ip-methodSentOnline" });
        }
      } else {
        if (window.dataLayer !== undefined) {
          window.dataLayer.push({ event: "ip-methodSentOffline" });
        }
      }
      this.checkStepper();
      this.loading = true;
      if (window.dataLayer !== undefined) {
        window.dataLayer.push({ event: "methodSent" });
      }
      localStorage.setItem("method", JSON.stringify(this.method));
      this.$store.commit("updateMethod", { method: this.method.status });
      this.$emit("pushNext");
      this.loading = false;
      return true;
    },
    validate(witherror = true) {
      var result =
        this.$refs.mainForm.validate() && this.method.status !== null;
      if (!witherror && !result) {
        this.$refs.mainForm.resetValidation();
      }
      // if (witherror && this.method.status == null) {
      //   result = false
      //   Vue.set(
      //     this.errors,
      //     "method",
      //     "Необходимо выбрать способ регистрации"
      //   );
      // }
      setTimeout(
        function () {
          this.$store.commit("isFormValid", ["method", result]);
        }.bind(this),
        0
      );
      return result;
    },
  },
};
</script>
