import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import ru from "vuetify/es5/locale/ru";

export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru",
  },
  theme: {
    themes: {
      light: {
        primary: "#000000",
        secondary: "#F9F9F9",
        accent: "#FFDF39",
        info: "#2196F3",
        success: "#99E55E",
        warning: "#FFC107",
        appbg: "#F9F9F9",
        btn_gray: "#EBEBEB",
        field_bg: "#FFFFFF",
        field_dark_gray: "#828282",
        light_warning: "#FEF2DC",
      },
    },
  },
});
