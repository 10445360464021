<template>
  <v-app>
    <v-main app>
      <h3 class="text-h4 mb-5 mb-sm-10">Виды деятельности</h3>
      <p class="mb-5 text-body-1">
        Выберите виды деятельности для вашего бизнеса
      </p>
      <div v-show="activities.okveds.length">
        <v-card
          v-for="okved in activities.okveds"
          :key="okved.id"
          class="mx-auto mb-5 pa-4 v-card_border"
          :class="{ active: activities.generalOkvedId == okved.id }"
        >
          <v-card-title class="pa-0 flex-nowrap text-break align-start">
            <span style="width: 80px" class="text-no-wrap flex-shrink-0 mr-2">{{
              okved.code
            }}</span>
            <v-card-subtitle class="body-1 px-0 pt-1 pb-2"
              >{{ okved.title }}
            </v-card-subtitle>
            <v-spacer></v-spacer>
            <v-btn icon @click="removeOkved(okved.id)" class="text--primary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-actions class="px-0">
            <v-btn
              @click="setGeneral(okved.id)"
              :depressed="activities.generalOkvedId == okved.id"
              :class="
                activities.generalOkvedId == okved.id ? 'text--primary' : ''
              "
              :color="
                activities.generalOkvedId == okved.id ? 'accent' : 'btn_gray'
              "
              small
            >
              <v-icon
                v-if="activities.generalOkvedId == okved.id"
                small
                class="mr-2"
                >mdi-check
              </v-icon>
              {{
                activities.generalOkvedId == okved.id
                  ? "Основной"
                  : "Сделать основным"
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <v-alert dense outlined type="error" v-if="errors.activities">
        {{ errors.activities }}
      </v-alert>
      <v-alert dense outlined type="error" v-if="errors.mainActivities">
        {{ errors.mainActivities }}
      </v-alert>
      <v-dialog
        v-model="addForm"
        max-width="950px"
        scrollable
        @click:outside="closeForm"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            class="_full-width-xs"
          >
            Добавить ОКВЭД
          </v-btn>
        </template>

        <v-card height="80vh" class="pb-4 pb-sm-8 px-0">
          <v-tabs v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#directory"> Справочник ОКВЭД</v-tab>
            <v-tab href="#receipes"> Готовые наборы</v-tab>
          </v-tabs>
          <v-card-text class="pa-0 pa-sm-3 fill-height">
            <v-tabs-items v-model="tab">
              <v-tab-item :value="'directory'">
                <v-card flat>
                  <v-card-text>
                    <v-container class="px-0">
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="query"
                            label="Поиск по названию или номерам ОКВЭД"
                            placeholder="Например, 47.99 или производство"
                            clearable
                            filled
                            dense
                            background-color="field_bg"
                            class="rounded-0 mb-4 mb-sm-1"
                          ></v-text-field>
                          <v-list
                            v-if="query"
                            flat
                            subheader
                            two-line
                            class="_no-bg"
                          >
                            <v-list-item-group
                              v-if="results.length"
                              v-model="selectedOkvedsIds"
                              multiple
                            >
                              <v-list-item
                                v-for="item in results"
                                :key="item.id"
                                :value="item.id"
                                class="px-0 px-sm-4 _border-top"
                                :disabled="isactive(item.id)"
                              >
                                <template v-slot:default="{ active }">
                                  <v-list-item-action
                                    class="align-self-start mr-2 ml-0 mx-sm-8"
                                  >
                                    <v-checkbox
                                      :input-value="active"
                                    ></v-checkbox>
                                  </v-list-item-action>

                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="font-weight-bold mb-2 mb-sm-0"
                                      >{{ item.code }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-pre-wrap"
                                      >{{ item.title }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </v-list-item-group>
                            <div
                              v-else
                              class="py-10 text-center"
                              flat
                              min-height="200"
                            >
                              Ничего не найдено
                            </div>
                          </v-list>
                          <div
                            v-else
                            class="py-10 text-center"
                            flat
                            min-height="200"
                          >
                            Начните вводить искомый ОКВЭД по номеру или названию
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'receipes'">
                <v-card flat>
                  <v-card-text>
                    <v-chip-group
                      v-model="collection"
                      active-class="black accent-4 white--text"
                      :column="this.$vuetify.breakpoint.name !== 'xs'"
                    >
                      <v-chip
                        v-for="collection in collections"
                        :key="collection.id"
                        @click="okvedsByCollection(collection.id)"
                        class="my-0 my-sm-1"
                        >{{ collection.title }}
                      </v-chip>
                    </v-chip-group>
                    <v-list
                      flat
                      two-line
                      v-if="collectionOkveds"
                      class="pt-2 pt-sm-7 _no-bg"
                    >
                      <v-list-item-group
                        v-model="selectedOkvedsIds"
                        multiple
                        active-class=""
                      >
                        <v-list-item
                          v-for="okved in collectionOkveds"
                          :key="okved.id"
                          :value="okved.id"
                          class="px-0 px-sm-4 _border-top"
                          :disabled="isactive(okved.id)"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-action
                              class="align-self-start mr-2 ml-0 mx-sm-8"
                            >
                              <v-checkbox
                                :input-value="active || isactive(okved.id)"
                              ></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title
                                class="font-weight-bold mb-2 mb-sm-0"
                                >{{ okved.code }}
                              </v-list-item-title>
                              <v-list-item-subtitle class="text-pre-wrap"
                                >{{ okved.title }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <div v-else class="py-10 text-center" flat min-height="200">
                      Выберите подходящее направление вашего бизнеса, мы покажем
                      соответсвующие ОКВЭДы
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions
            class="mx-0 mx-sm-8 px-8 px-sm-0 pt-7 pb-0 d-flex justify-space-between _border-top"
          >
            <v-btn @click="closeForm" class="text--primary"> Отмена </v-btn>
            <v-btn
              color="accent"
              :disabled="selectedOkvedsIds.length == 0"
              @click="addSelected"
              class="text--primary"
            >
              Добавить
              <v-avatar
                size="22"
                class="ml-1 amber"
                v-if="selectedOkvedsIds.length > 0"
              >
                <span>{{ selectedOkvedsIds.length }}</span>
              </v-avatar>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row class="mt-8">
        <v-col class="text-left">
          <v-btn
            color="accent"
            class="text--primary"
            :disabled="activities.okveds.length <= 0"
            @click="newDoc('legal')"
            >Продолжить для ООО
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn
            color="accent"
            class="text--primary float-right"
            :disabled="activities.okveds.length <= 0"
            @click="newDoc('individual')"
            >Регистрировать ИП
          </v-btn>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      activities: {
        generalOkvedId: null,
        okveds: [],
      },
      type: null,
      addForm: false,
      tab: null,
      collection: null,
      collectionOkveds: null,
      query: null,
      results: [],
      selectedOkvedsIds: [],
      collections: null,
      loading: false,
      errors: {
        activities: null,
        mainActivities: null,
      },
    };
  },
  watch: {
    query(val) {
      if (!val) this.results = [];
      else if (val.length > 1) {
        this.axios
          .get(process.env.VUE_APP_API + "/search", {
            params: { query: val },
          })
          .then((response) => {
            this.results = [];
            this.results = response.data;
          });
      } else this.results = [];
    },
    "activities.okveds"(val) {
      if (val) {
        Vue.set(this.errors, "activities", null);
      }
    },
    "activities.generalOkvedId"(val) {
      if (val) {
        Vue.set(this.errors, "mainActivities", null);
      }
    },
    "$store.state.formValidation"(value) {
      if (value) {
        var self = this;
        setTimeout(function () {
          if (!self.next(false)) {
            self.$store.commit("formValidation", false);
          }
        }, 0);
      }
    },
  },
  methods: {
    newDoc(type) {
      this.$store.commit("clearStore");
      this.$store.commit("initStore", { type: type });
      this.next(true, type);
    },
    next(witherror = true, type) {
      if (!this.validate(witherror)) {
        return false;
      }
      this.loading = true;
      localStorage.setItem("activities", JSON.stringify(this.activities));
      localStorage.setItem("edit", 2);
      const payload = {
        activities: this.activities,
      };
      this.$store.commit("updateActivities", payload);
      setTimeout(function () {
        if (type == "legal") {
          if (window.dataLayer !== undefined) {
            window.dataLayer.push({ event: "okvedSent" });
          }
          location.href = "/app/#/legal/name";
        } else if (type == "individual") {
          if (window.dataLayer !== undefined) {
            window.dataLayer.push({ event: "ipOkvedSent" });
          }
          location.href = "/app/#/individual/appdoc";
        }
      }, 100);
    },
    setGeneral(id) {
      this.activities.generalOkvedId = id;
    },
    removeOkved(id) {
      this.activities.okveds = this.activities.okveds.filter(
        (item) => item.id !== id
      );
      if (this.activities.generalOkvedId == id)
        this.activities.generalOkvedId = null;
    },
    addSelected() {
      this.selectedOkvedsIds.forEach((item) => {
        this.axios
          .get(process.env.VUE_APP_API + "/okved/" + item)
          .then((response) => {
            // this.okveds.push(response.data);
            let tmpOkveds = [...this.activities.okveds, response.data];
            tmpOkveds = tmpOkveds.filter(
              (v, i, a) => a.findIndex((t) => t.id === v.id) === i
            );
            this.activities.okveds = tmpOkveds;
            // if (this.okveds.indexOf(response.data.id) === -1) {
            //   this.okveds.push(response.data);
            //   console.log(this.okveds);
            // }
          });
      });
      // this.okveds = [...new Set(tmp.concat(this.okveds))]
      // let newArr = [...this.okveds, ...this.tmp]
      // console.log('NewArr', newArr)
      // this.okveds = newArr
      this.closeForm();
    },
    closeForm() {
      this.addForm = false;
      this.results = [];
      this.selectedOkvedsIds = [];
      this.collection = null;
      this.query = null;
    },
    okvedsByCollection(id) {
      this.selectedOkvedsIds = [];
      let options = {
        method: "get",
        url: process.env.VUE_APP_API + "/collections/" + id,
        mode: "cors",
      };
      this.axios(options)
        .then((result) => {
          this.collectionOkveds = result.data;
        })
        .catch((error) => console.log("error", error));
    },
    validate(witherror) {
      let result = true;
      if (
        (this.activities.okveds.length > 0 &&
          !this.activities.generalOkvedId) ||
        this.activities.okveds.length <= 0
      ) {
        result = false;
      }
      if (witherror && !result) {
        if (this.activities.okveds.length <= 0) {
          Vue.set(
            this.errors,
            "activities",
            "Необходимо добавить минимум один вид деятельности"
          );
        }
        if (
          this.activities.okveds.length > 0 &&
          !this.activities.generalOkvedId
        ) {
          Vue.set(
            this.errors,
            "mainActivities",
            "Необходимо выбрать основной вид деятельности"
          );
        }
      }
      setTimeout(
        function () {
          this.$store.commit("isFormValid", ["activities", result]);
        }.bind(this),
        0
      );
      return result;
    },
    isactive(id) {
      for (var i = 0; i < this.activities.okveds.length; i++) {
        if (this.activities.okveds[i].id == id) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    let options = {
      method: "get",
      url: process.env.VUE_APP_API + "/collections",
      mode: "cors",
    };
    this.axios(options)
      .then((result) => {
        this.collections = result.data;
      })
      .catch((error) => console.log("error", error));
  },
  mounted() {
    if (localStorage.getItem("activities")) {
      let activities = JSON.parse(localStorage.getItem("activities"));
      this.activities = activities;
    }
    if (this.$store.state.formValidation) {
      var self = this;
      setTimeout(function () {
        if (!self.next(false)) {
          self.$store.commit("formValidation", false);
        }
      }, 0);
    }
  },
};
</script>

<style lang="scss">
//@import '~vuetify/src/styles/styles.sass';
@import "styles/variables.scss";
@import "styles/main.scss";

.v-application {
  background: none !important;
}

.v-application--wrap {
  min-height: inherit !important;
}

.v-tab {
  text-decoration: none !important;
}
</style>
