<template>
  <v-row class="fill-height ma-0 flex-column flex-sm-row main-container">
    <v-col
      class="stepper-outer py-5 px-5 py-sm-8 py-lg-12 pb-1 pb-sm-5 pr-0 px-sm-3 col-sm-4 col-md-3 flex-grow-0 flex-sm-grow-1 gray-bg"
    >
      <stepper
        id="stepper"
        ref="stepper"
        :steps="onlyEnabledSteps"
        :currentstep="stepper.currentStep"
      />
    </v-col>
    <v-col class="py-lg-12 pa-3 pa-sm-6 col-sm-8 col-md-9">
      <v-row class="justify-center">
        <v-col class="col-lg-11 pt-5 pb-8 px-5 pa-sm-3">
          <!--<div class="text-h4 mb-5 mb-sm-10">Документы для регистрации ИП</div>-->
          <div
            class="text-h4 mb-4"
            v-if="onlyEnabledSteps[stepper.currentStep - 1]"
          >
            {{ onlyEnabledSteps[stepper.currentStep - 1].title }}
          </div>
          <router-view @pushPrev="pushPrev" @pushNext="pushNext" />
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="dialog" persistent max-width="480">
      <v-card>
        <v-card-title class="text-break headline">
          У вас есть сохраненный черновик.
        </v-card-title>
        <v-card-text>
          Начать заполнение документов с нуля, потеряв сохраненные данные, или
          продолжить заполнение?
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="newDoc"> Начать заново</v-btn>
          <v-spacer />
          <v-btn color="green darken-1" text @click="dialog = false">
            Продолжить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from "vue";
import Stepper from "@/components/Stepper.vue";

export default {
  components: {
    stepper: Stepper,
  },
  data() {
    return {
      dialog: false,
      stepper: {
        steps: this.$store.state.steps.individual,
        currentStep: 0,
      },
      routerSteps: this.$router.options.routes[3].children,
    };
  },
  computed: {
    onlyEnabledSteps() {
      var activeRoutes = this.routerSteps.filter(function (route) {
        return route.meta.disabled === false;
      });
      return this.stepper.steps.filter(function (step) {
        var currentRouteStep = activeRoutes.find(function (routeStep) {
          return routeStep.name == step.name;
        });
        return currentRouteStep && currentRouteStep.meta.disabled == false;
      });
    },
  },
  watch: {
    $route: function (value) {
      this.routerSteps = this.$router.options.routes[3].children;
      this.setStepIndex(value);
      var stepperDiv = document.getElementById("stepper");
      if (stepperDiv.childNodes[this.stepper.currentStep - 1] != undefined) {
        stepperDiv.scrollLeft =
          stepperDiv.childNodes[this.stepper.currentStep - 1].offsetLeft - 20;
      }
    },
    deep: true,
  },
  created() {
    this.setStepIndex(this.$route);
    if (localStorage.getItem("type") !== "individual") {
      this.newDoc();
      return false;
    }
    this.$store.commit("initStore", { type: "individual" });
  },
  beforeDestroy() {
    this.$store.commit("isFormValid", false);
  },
  mounted() {
    var storageKeys = Object.keys(localStorage);
    this.$store.commit("setType", "individual");
    if (storageKeys.length > 0 && !localStorage.getItem("edit")) {
      for (let item of storageKeys) {
        if (
          [
            "method",
            "activities",
            "appdoc",
            "taxes",
            "founders",
            "bank",
            "sign",
          ].includes(item)
        ) {
          this.dialog = true;
          break;
        }
      }
      this.$store.commit("formValidation", true);
    }
    if (localStorage.getItem("edit") == 1) {
      this.$store.commit("isFormValid", true);
    } else {
      this.$store.commit("formValidation", true);
    }

    if (localStorage.getItem("method")) {
      var method = JSON.parse(localStorage.getItem("method"));
      if (method) {
        if (method.status == 1) {
          Vue.set(
            this.$router.options.routes[3].children[6].meta,
            "disabled",
            true
          );
          Vue.set(
            this.$router.options.routes[3].children[7].meta,
            "disabled",
            false
          );
          Vue.set(this.$store.state.steps.individual[6], "disabled", true);
          Vue.set(this.$store.state.steps.individual[7], "disabled", false);
        } else {
          Vue.set(
            this.$router.options.routes[3].children[6].meta,
            "disabled",
            false
          );
          Vue.set(
            this.$router.options.routes[3].children[7].meta,
            "disabled",
            true
          );
          Vue.set(this.$store.state.steps.individual[6], "disabled", false);
          Vue.set(this.$store.state.steps.individual[7], "disabled", true);
        }
      }
    }
    localStorage.removeItem("edit");
    if (this.$route.path != "/individual/activities") {
      this.$router.push("/individual/activities");
    }
  },
  methods: {
    pushPrev() {
      var i = 2;
      if (this.onlyEnabledSteps[this.stepper.currentStep - i].disabled) {
        i--;
      }
      this.$router.push(
        this.onlyEnabledSteps[this.stepper.currentStep - i].link
      );
    },
    pushNext() {
      var i = 0;
      if (this.onlyEnabledSteps[this.stepper.currentStep + i].disabled) {
        i++;
      }
      if (
        this.$route.path !=
        this.onlyEnabledSteps[this.stepper.currentStep + i].link
      ) {
        this.$router.push(
          this.onlyEnabledSteps[this.stepper.currentStep + i].link
        );
      }
    },
    setStepIndex(value) {
      var self = this;
      this.onlyEnabledSteps.forEach(function (route, i) {
        if (route.name == value.name) {
          self.$set(self.stepper, "currentStep", i + 1);
        }
      });
    },
    newDoc() {
      this.$store.commit("clearStore");
      this.dialog = false;
      if (this.$route.name != "activities") {
        this.$router.push("/individual/activities");
      }
      this.$store.commit("isFormValid", false);
      this.$store.commit("formValidation", true);
    },
  },
};
</script>
