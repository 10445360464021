<template>
  <div class="stepper pb-4 pb-sm-0">
    <span v-for="(step, i) in steps" :key="step.name">
      <template v-if="isActive(i)">
        <router-link
          v-if="checkStep(i)"
          :key="i"
          class="text-subtitle-1 d-flex text-decoration-none mb-0 mb-sm-4 mr-7 mr-sm-0"
          :to="step.link"
          :class="[
            currentstep === i + 1 ? 'active' : '',
            checkStep(i) ? '' : 'text--disabled',
          ]"
          @click="changeStep(i)"
        >
          <v-icon
            dense
            medium
            :class="
              currentstep === i + 1
                ? 'black--text'
                : currentstep <= i
                ? ''
                : 'yellow--text'
            "
          >
            mdi-checkbox-marked-circle
          </v-icon>
          <span class="ml-3 ml-sm-5 d-block">{{ step.title }}</span>
        </router-link>
        <span
          v-else
          class="text-subtitle-1 d-flex text-decoration-none text--disabled mb-0 mb-sm-4 mr-7 mr-sm-0"
          v-bind:key="i"
        >
          <v-icon dense medium> mdi-checkbox-marked-circle </v-icon>
          <span class="ml-3 ml-sm-5 d-block">{{ step.title }}</span>
        </span>
      </template>
    </span>
  </div>
</template>

<script>
export default {
  name: "Stepper",
  props: {
    steps: Array,
    currentstep: Number,
  },
  computed: {
    years() {
      return "года";
    },
    computedBirthDate: {
      get() {
        return this.formatDate(this.currentUser.birthDate);
      },
      set(value) {
        this.currentUser.birthDate = value;
      },
    },
    computedPassportDate: {
      get() {
        return this.formatDate(this.currentUser.passportDate);
      },
      set(value) {
        this.currentUser.passportDate = value;
      },
    },
  },
  methods: {
    isActive(it) {
      if (this.steps[it].disabled === true) {
        return false;
      }
      return true;
    },
    checkStep(it) {
      for (var i = 0; i <= it; i++) {
        if (
          (!this.$store.state.isFormValid[this.steps[i].name] &&
            this.currentstep !== i + 1 &&
            i > 0 &&
            !this.$store.state.isFormValid[this.steps[i - 1].name]) ||
          (!this.$store.state.document.id && i !== 0)
        ) {
          return false;
        }
      }
      return true;
    },
    changeStep(i) {
      if (this.steps[i - 1] != undefined && !this.steps[i - 1].completed) {
        return false;
      }
      this.$emit("change-step", i);
    },
  },
};
</script>
