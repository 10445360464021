<template>
  <v-form>
    <v-row>
      <v-col>
        <p class="mb-5 text-body-1">
          Выберите виды деятельности для вашего бизнеса
        </p>
        <div v-show="activities.okveds.length">
          <v-card
            v-for="okved in activities.okveds"
            :key="okved.id"
            class="mx-auto mb-5 pa-4 v-card_border"
            :class="{ active: activities.generalOkvedId == okved.id }"
          >
            <v-card-title class="pa-0 flex-nowrap text-break align-start">
              <span
                style="width: 80px"
                class="text-no-wrap flex-shrink-0 mr-2"
                >{{ okved.code }}</span
              >
              <v-card-subtitle class="body-1 px-0 pt-1 pb-2">
                {{ okved.title }}
              </v-card-subtitle>
              <v-spacer />
              <v-btn icon class="text--primary" @click="removeOkved(okved.id)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-actions class="px-0">
              <v-btn
                :depressed="activities.generalOkvedId == okved.id"
                :class="
                  activities.generalOkvedId == okved.id ? 'text--primary' : ''
                "
                :color="
                  activities.generalOkvedId == okved.id ? 'accent' : 'btn_gray'
                "
                small
                @click="setGeneral(okved.id)"
              >
                <v-icon
                  v-if="activities.generalOkvedId == okved.id"
                  small
                  class="mr-2"
                >
                  mdi-check
                </v-icon>
                {{
                  activities.generalOkvedId == okved.id
                    ? "Основной"
                    : "Сделать основным"
                }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <v-alert v-if="errors.activities" dense outlined type="error">
          {{ errors.activities }}
        </v-alert>
        <v-alert v-if="errors.mainActivities" dense outlined type="error">
          {{ errors.mainActivities }}
        </v-alert>

        <v-dialog
          v-model="addForm"
          max-width="950px"
          scrollable
          @click:outside="closeForm"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              class="_full-width-xs"
              v-on="on"
            >
              Добавить ОКВЭД
            </v-btn>
          </template>
          <v-card height="80vh" class="pb-4 pb-sm-8 px-0">
            <v-tabs v-model="tab" centered>
              <v-tabs-slider />
              <v-tab href="#directory" @click="selectedOkvedsIds = []">
                Справочник ОКВЭД
              </v-tab>
              <v-tab href="#receipes" @click="selectedOkvedsIds = []">
                Готовые наборы
              </v-tab>
            </v-tabs>
            <v-card-text class="pa-0 pa-sm-3 fill-height">
              <v-tabs-items v-model="tab">
                <v-tab-item :value="'directory'">
                  <v-card flat>
                    <v-card-text>
                      <v-container class="px-0">
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="query"
                              label="Поиск по названию или номерам ОКВЭД"
                              placeholder="Например, 47.99 или производство"
                              clearable
                              filled
                              dense
                              background-color="field_bg"
                              class="rounded-0 mb-4 mb-sm-1"
                            />
                            <v-list
                              v-if="query"
                              flat
                              subheader
                              two-line
                              class="_no-bg"
                            >
                              <v-list-item-group
                                v-if="results.length"
                                v-model="selectedOkvedsIds[9999]"
                                multiple
                              >
                                <v-list-item
                                  v-for="item in results"
                                  :key="item.id"
                                  :value="item"
                                  class="px-0 px-sm-4 _border-top"
                                  :disabled="isactive(item.id)"
                                >
                                  <template v-slot:default="{ active }">
                                    <v-list-item-action
                                      class="align-self-start mr-2 ml-0 mx-sm-8"
                                    >
                                      <v-checkbox :input-value="active" />
                                    </v-list-item-action>

                                    <v-list-item-content>
                                      <v-list-item-title
                                        class="font-weight-bold mb-2 mb-sm-0"
                                      >
                                        {{ item.code }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle
                                        class="text-pre-wrap"
                                      >
                                        {{ item.title }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </v-list-item-group>
                              <div v-else class="py-10" flat min-height="200">
                                <p class="mb-6 text-center text-h6">
                                  Нам не удалось найти подходящие ОКВЭД
                                </p>
                                <p class="mb-2">
                                  Попробуйте повторить поиск с учетом
                                  рекомендаций:
                                </p>
                                <ul>
                                  <li class="mb-2">
                                    Переформулируйте запрос: грузоперевозка →
                                    перевозка грузов
                                  </li>
                                  <li class="mb-2">
                                    Попробуйте более общие синонимы: пончики →
                                    кондитерские изделия
                                  </li>
                                  <li class="mb-2">
                                    Используйте глаголы, подходящие вашему
                                    бизнесу: производство, хранение,
                                    изготовление
                                  </li>
                                </ul>
                                <p class="mb-2">
                                  Не получилось?
                                  <a href="javascript:jivo_api.open()"
                                    >Обратитесь в онлайн-чат</a
                                  >, мы постараемся помочь.
                                </p>
                              </div>
                            </v-list>
                            <div
                              v-else
                              class="py-10 text-center"
                              flat
                              min-height="200"
                            >
                              Начните вводить искомый ОКВЭД по номеру или
                              названию
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'receipes'">
                  <v-card flat>
                    <v-card-text>
                      <v-chip-group
                        v-model="collection"
                        active-class="black accent-4 white--text"
                        :column="this.$vuetify.breakpoint.name !== 'xs'"
                      >
                        <v-chip
                          v-for="(collection, collectionKey) in collections"
                          :key="collection.id"
                          class="my-0 my-sm-1"
                          @click="okvedsByCollection(collection.id)"
                        >
                          {{ collection.title }}
                          <v-avatar
                            right
                            class="ml-1 white black--text"
                            v-if="activeInCollection(collectionKey) > 0"
                          >
                            {{ activeInCollection(collectionKey) }}
                          </v-avatar>
                        </v-chip>
                      </v-chip-group>
                      <v-list
                        v-if="collectionOkveds"
                        flat
                        two-line
                        class="pt-2 pt-sm-7 _no-bg"
                      >
                        <v-list-item-group
                          v-model="selectedOkvedsIds[collection]"
                          multiple
                          active-class=""
                        >
                          <v-list-item
                            v-for="okved in collectionOkveds"
                            :key="okved.id"
                            :value="okved"
                            class="px-0 px-sm-4 _border-top"
                            :disabled="isactive(okved.id)"
                          >
                            <template v-slot:default="{ active }">
                              <v-list-item-action
                                class="align-self-start mr-2 ml-0 mx-sm-8"
                              >
                                <v-checkbox
                                  :input-value="
                                    active ||
                                    isactive(okved.id) ||
                                    isselected(okved.id)
                                  "
                                />
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title
                                  class="font-weight-bold mb-2 mb-sm-0"
                                >
                                  {{ okved.code }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-pre-wrap">
                                  {{ okved.title }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div
                        v-else
                        class="py-10 text-center"
                        flat
                        min-height="200"
                      >
                        Выберите подходящее направление вашего бизнеса, мы
                        покажем соответсвующие ОКВЭДы
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
            <v-card-actions
              class="mx-0 mx-sm-8 px-8 px-sm-0 pt-7 pb-0 d-flex justify-space-between _border-top"
            >
              <v-btn class="text--primary" @click="closeForm"> Отмена </v-btn>
              <v-btn
                color="accent"
                :disabled="amountOfSelected == 0"
                class="text--primary"
                @click="addSelected"
              >
                Добавить
                <v-avatar
                  v-if="amountOfSelected > 0"
                  size="22"
                  class="ml-1 amber"
                >
                  <span>{{ amountOfSelected }}</span>
                </v-avatar>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-caption text--secondary"
        >Нажимая &laquo;Сохранить&raquo;, вы даете согласие на обработку
        персональных данных и принимаете условия
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <a
              target="_blank"
              href="https://dokia.ru/privacy-policy/"
              @click.stop
              v-on="on"
              >политики конфиденциальности</a
            >
          </template>
          Откроется в новом окне
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <v-btn
          color="accent"
          class="text--primary"
          @click="next(true)"
          :disabled="!$store.state.isFormValid.activities"
        >
          Сохранить &rarr;
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      activities: {
        generalOkvedId: null,
        okveds: [],
      },
      addForm: false,
      tab: null,
      collection: null,
      collectionOkveds: null,
      query: null,
      results: [],
      selectedOkvedsIds: {},
      collections: null,
      loading: false,
      errors: {
        activities: null,
        mainActivities: null,
      },
    };
  },
  computed: {
    amountOfSelected() {
      var count = 0;
      if (Object.keys(this.selectedOkvedsIds).length > 0) {
        Object.entries(this.selectedOkvedsIds).forEach(([key, value]) => {
          count += value.length;
        });
      }
      return count;
    },
  },
  watch: {
    query(val) {
      if (!val) this.results = [];
      else if (val.length > 1) {
        this.axios
          .get(process.env.VUE_APP_API + "/search", {
            params: { query: val },
          })
          .then((response) => {
            this.results = [];
            this.results = response.data;
            if (window.dataLayer !== undefined) {
              window.dataLayer.push({ event: "okvedSearchQuery" });
              if (response.data.length <= 0) {
                window.dataLayer.push({ event: "okvedSearchEmpty" });
              }
            }
          });
      } else this.results = [];
    },
    "activities.okveds"(val) {
      if (val) {
        Vue.set(this.errors, "activities", null);
      }
      this.preValid();
    },
    "activities.generalOkvedId"(val) {
      if (val) {
        Vue.set(this.errors, "mainActivities", null);
      }
      this.preValid();
    },
    "$store.state.formValidation"(value) {
      if (value) {
        var self = this;
        setTimeout(function () {
          if (!self.next(false)) {
            self.$store.commit("formValidation", false);
          }
        }, 0);
      }
    },
  },
  created() {
    let options = {
      method: "get",
      url: process.env.VUE_APP_API + "/collections",
      mode: "cors",
    };
    this.axios(options)
      .then((result) => {
        this.collections = result.data;
      })
      .catch((error) => console.log("error", error));
  },
  mounted() {
    var self = this;
    if (window.dataLayer !== undefined) {
      if (this.$store.state.type == "legal") {
        window.dataLayer.push({ event: "okvedPageLoaded" });
      } else {
        window.dataLayer.push({ event: "ip-okvedPageLoaded" });
      }
    }
    if (localStorage.getItem("activities")) {
      let activities = JSON.parse(localStorage.getItem("activities"));
      this.activities = activities;
      this.activities.okveds.forEach(function (item, key) {
        if (!item.collection) {
          // На случай, если у юзера записан json старого формата
          self.axios
            .get(process.env.VUE_APP_API + "/okved/" + item.id)
            .then((response) => {
              self.activities.okveds[key] = response.data;
              localStorage.setItem(
                "activities",
                JSON.stringify(self.activities)
              );
            });
        }
      });
    }
    if (this.$store.state.formValidation) {
      setTimeout(function () {
        if (!self.next(false)) {
          self.$store.commit("formValidation", false);
        }
      }, 0);
    }
  },
  methods: {
    next(witherror = true) {
      if (!this.validate(witherror)) {
        return false;
      }
      this.loading = true;
      localStorage.setItem("activities", JSON.stringify(this.activities));
      const payload = {
        activities: this.activities,
      };
      if (window.dataLayer !== undefined) {
        if (this.$route.matched[0].name == "Legal") {
          window.dataLayer.push({ event: "okvedSent" });
        } else if (this.$route.matched[0].name == "Individual") {
          window.dataLayer.push({ event: "ipOkvedSent" });
        }
      }
      var self = this;
      this.$store.commit("initStore", {
        type: this.$store.state.type,
        callback: function () {
          self.$store.commit("updateActivities", payload);
          self.$emit("pushNext");
          self.loading = false;
        },
      });
      return true;
    },
    setGeneral(id) {
      this.activities.generalOkvedId = id;
      localStorage.setItem("activities", JSON.stringify(this.activities));
    },
    removeOkved(id) {
      this.activities.okveds = this.activities.okveds.filter(
        (item) => item.id !== id
      );
      if (this.activities.generalOkvedId == id) {
        this.activities.generalOkvedId = null;
      }
      localStorage.setItem("activities", JSON.stringify(this.activities));
    },
    addSelected() {
      Object.entries(this.selectedOkvedsIds).forEach(([key, value]) => {
        value.forEach((item) => {
          this.axios
            .get(process.env.VUE_APP_API + "/okved/" + item.id)
            .then((response) => {
              // this.okveds.push(response.data);
              let tmpOkveds = [...this.activities.okveds, response.data];
              tmpOkveds = tmpOkveds.filter(
                (v, i, a) => a.findIndex((t) => t.id === v.id) === i
              );
              this.activities.okveds = tmpOkveds;
              localStorage.setItem(
                "activities",
                JSON.stringify(this.activities)
              );
            });
        });
      });
      this.closeForm();
    },
    closeForm() {
      this.addForm = false;
      this.results = [];
      this.selectedOkvedsIds = [];
      this.collection = null;
      this.query = null;
    },
    okvedsByCollection(id) {
      let options = {
        method: "get",
        url: process.env.VUE_APP_API + "/collections/" + id,
        mode: "cors",
      };
      this.axios(options)
        .then((result) => {
          this.collectionOkveds = result.data;
        })
        .catch((error) => console.log("error", error));
    },
    activeInCollection(id) {
      return (
        (this.selectedOkvedsIds[id] ? this.selectedOkvedsIds[id].length : 0) +
        this.getAmountByCollectionId(id)
      );
    },
    getAmountByCollectionId(id) {
      var count = 0;
      if (this.activities.okveds.length > 0) {
        for (var i = 0; i < this.activities.okveds.length; i++) {
          if (
            this.activities.okveds[i].collection &&
            this.activities.okveds[i].collection.length > 0
          ) {
            for (
              var ii = 0;
              ii < this.activities.okveds[i].collection.length;
              ii++
            ) {
              if (
                this.activities.okveds[i].collection[ii].id ==
                this.collections[id].id
              ) {
                count++;
              }
            }
          }
        }
      }
      return count;
    },
    preValid() {
      if (
        this.activities.okveds.length > 0 &&
        this.activities.generalOkvedId !== null
      ) {
        this.$store.commit("isFormValid", ["activities", true]);
      } else {
        this.$store.commit("isFormValid", ["activities", false]);
      }
    },
    validate(witherror) {
      let result = true;
      if (
        (this.activities.okveds.length > 0 &&
          !this.activities.generalOkvedId) ||
        this.activities.okveds.length <= 0
      ) {
        result = false;
      }
      if (witherror && !result) {
        if (this.activities.okveds.length <= 0) {
          Vue.set(
            this.errors,
            "activities",
            "Необходимо добавить минимум один вид деятельности"
          );
        }
        if (
          this.activities.okveds.length > 0 &&
          !this.activities.generalOkvedId
        ) {
          Vue.set(
            this.errors,
            "mainActivities",
            "Необходимо выбрать основной вид деятельности"
          );
        }
      }
      setTimeout(
        function () {
          this.$store.commit("isFormValid", ["activities", result]);
        }.bind(this),
        0
      );
      return result;
    },
    isactive(id) {
      for (var i = 0; i < this.activities.okveds.length; i++) {
        if (this.activities.okveds[i].id == id) {
          return true;
        }
      }
      return false;
    },
    isselected(id) {
      if (this.selectedOkvedsIds[this.collection]) {
        for (
          var i = 0;
          i < this.selectedOkvedsIds[this.collection].length;
          i++
        ) {
          if (this.selectedOkvedsIds[this.collection][i].id == id) {
            return true;
          }
        }
      }
      return false;
    },
  },
};
</script>
