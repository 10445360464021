<template>
  <v-row class="main-container">
    <v-col class="py-12">
      <div class="text-h5 text-md-h4 mx-4">Мои документы</div>
      <router-view />
      <v-btn color="accent" class="text--primary ma-4" @click="newIndividual()">
        Зарегистрировать ИП
      </v-btn>
      <v-btn color="accent" class="text--primary ma-4" @click="newLegal()">
        Открыть ООО
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  // mounted() {
  //   if (!this.$auth.check()) {
  //     this.$store.state.loginPopup = true;
  //   }
  // },
  methods: {
    newLegal() {
      this.$store.commit("clearStore");
      this.$router.push({ name: "Legal" });
      this.$store.commit("isFormValid", false);
      this.$store.commit("formValidation", true);
    },
    newIndividual() {
      this.$store.commit("clearStore");
      this.$router.push({ name: "Individual" });
      this.$store.commit("isFormValid", false);
      this.$store.commit("formValidation", true);
    },
    auth() {
      this.$store.state.loginPopup = true;
    },
  },
};
</script>
