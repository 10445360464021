<template>
  <v-form ref="mainForm" v-model="$store.state.isFormValid.appdoc">
    <h3 class="mt-4 mb-4 font-weight-medium">Личные данные</h3>
    <v-row>
      <v-col class="col-12 col-sm-6">
        <v-text-field
          label="Гражданство"
          dense
          filled
          disabled
          background-color="field_bg"
          value="Гражданин РФ"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="appdoc.lastName"
          :rules="[rules.required, rules.text]"
          filled
          no-filter
          label="Фамилия"
          background-color="field_bg"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="appdoc.firstName"
          :rules="[rules.required, rules.text]"
          filled
          no-filter
          label="Имя"
          background-color="field_bg"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="appdoc.patronymic"
          :rules="[rules.required, rules.text]"
          filled
          no-filter
          label="Отчество"
          background-color="field_bg"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          :value="computedBirthDate"
          @input="debounceInput($event, 'saveContent', appdoc, 'birthDate')"
          v-mask="'##.##.####'"
          :rules="[rules.required, rules.date]"
          placeholder="01.01.1980"
          label="Дата рождения"
          filled
          background-color="field_bg"
        />
      </v-col>
      <v-col cols="6">
        <v-select
          :value="appdoc.sex"
          @input="debounceInput($event, 'saveContent', appdoc, 'sex')"
          :items="[
            { text: 'Мужской', value: 1 },
            { text: 'Женский', value: 2 },
          ]"
          label="Пол"
          required
          dense
          filled
          background-color="field_bg"
          :rules="[rules.required]"
          validate-on-blur
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <h3 class="mt-4 mb-4 font-weight-medium">Паспортные данные</h3>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="appdoc.passportNum"
          @input="debounceInput($event, 'saveContent', appdoc, 'passportNum')"
          v-mask="'#### ######'"
          dense
          label="Серия и номер паспорта"
          filled
          background-color="field_bg"
          :rules="[rules.requiredStr, rules.serialNumber]"
          validate-on-blur
          hide-details="auto"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="appdoc.fmsCode"
          @input="debounceInput($event, 'saveContent', appdoc, 'fmsCode')"
          v-mask="'###-###'"
          dense
          filled
          label="Код подразделения"
          background-color="field_bg"
          :rules="[rules.requiredStr, rules.branchCode]"
          validate-on-blur
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :value="appdoc.fms"
          @input="debounceInput($event, 'saveContent', appdoc, 'fms')"
          dense
          filled
          label="Кем выдан"
          background-color="field_bg"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="computedPassportDate"
          @input="debounceInput($event, 'saveContent', appdoc, 'passportDate')"
          v-mask="'##.##.####'"
          :rules="[rules.required, rules.date]"
          placeholder="01.01.1980"
          label="Дата выдачи паспорта"
          filled
          background-color="field_bg"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="appdoc.snils"
          @input="debounceInput($event, 'saveContent', appdoc, 'snils')"
          v-mask="'###-###-### ##'"
          filled
          dense
          background-color="field_bg"
          label="СНИЛС"
          :rules="[rules.snils]"
          validate-on-blur
          hide-details="auto"
        />
      </v-col>
    </v-row>

    <h3 class="mt-4 mb-4 font-weight-medium">Адрес в РФ</h3>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          :value="appdoc.address.source"
          @input="
            debounceInput($event, 'saveContent', appdoc.address, 'source')
          "
          :rules="[rules.requiredStr, rules.address]"
          validate-on-blur
          :items="addressItems"
          :loading="addressLoading"
          :search-input.sync="addressSearch"
          filled
          no-filter
          label="Адрес постоянной регистрации"
          background-color="field_bg"
          hide-details="auto"
          hide-no-data
        />
      </v-col>
      <v-col id="birthplace" cols="12" class="tooltip-wrapper">
        <v-tooltip absolute attach="#birthplace">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="appdoc.birthPlace"
              @input="
                debounceInput($event, 'saveContent', appdoc, 'birthPlace')
              "
              :rules="[rules.requiredStr]"
              validate-on-blur
              filled
              label="Место рождения"
              placeholder="Г. МОСКВА"
              background-color="field_bg"
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>Укажите место рождения как в паспорте</span>
        </v-tooltip>
      </v-col>
      <v-col class="col-12 mb-8">
        <v-text-field
          :value="appdoc.inn"
          @input="debounceInput($event, 'saveContent', appdoc, 'inn')"
          v-mask="'############'"
          filled
          dense
          background-color="field_bg"
          label="ИНН"
          :rules="[rules.inn]"
          validate-on-blur
          hide-details="auto"
        />
        <small>
          <a
            href="https://service.nalog.ru/inn.do"
            target="_blank"
            class="mt-2 ml-3 mb-1 d-block blue--text text-decoration-none"
            >Найти ИНН на nalog.ru</a
          >
        </small>
        <div v-if="!appdoc.inn">
          <small>
            Отсутствие ИНН, если он был присвоен физическому лицу, является
            причиной отказа в регистрации.
          </small>
        </div>
      </v-col>
    </v-row>
    <h3 class="mt-4 mb-4 font-weight-medium">Контактные данные</h3>
    <v-row>
      <v-col cols="6">
        <v-text-field
          :value="appdoc.email"
          @input="debounceInput($event, 'saveContent', appdoc, 'email')"
          filled
          dense
          background-color="field_bg"
          label="E-Mail"
          :rules="[rules.requiredStr]"
          validate-on-blur
          hide-details="auto"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="appdoc.phone"
          @input="debounceInput($event, 'saveContent', appdoc, 'phone')"
          v-mask="'+7 ### ###-##-##'"
          filled
          dense
          background-color="field_bg"
          label="Телефон"
          placeholder="+7"
          :rules="[rules.requiredStr, rules.phone]"
          validate-on-blur
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5 mt-sm-14">
      <v-col>
        <v-btn color="secondary" class="text--primary" @click="back()"
          >Назад
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="accent"
          class="text--primary"
          :loading="loading"
          @click="next"
          :disabled="!$store.state.isFormValid.appdoc"
        >
          Сохранить &rarr;
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      formType: null,
      addUserForm: false,
      addressItems: [],
      addressLoading: false,
      addressSearch: null,
      // fioItems: [],
      // fioLoading: false,
      // fio: null,
      fms: null,
      loading: false,
      error: false,
      errors: {
        appdoc: null,
      },
      appdoc: {
        firstName: null,
        lastName: null,
        // fullName: null,
        patronymic: null,
        sex: null,
        inn: null,
        snils: null,
        birthDate: null,
        passportNum: null,
        passportDate: null,
        fms: null,
        fmsCode: null,
        birthPlace: null,
        address: {
          postal_code: null,
          region_code: null,
          area_type: null,
          area: null,
          city_type: null,
          city: null,
          settlement_type: null,
          settlement: null,
          street_type: null,
          street: null,
          house_type_full: null,
          house: null,
          block_type_full: null,
          block: null,
          flat_type_full: null,
          flat: null,
          result: null,
        },
      },
      rules: {
        //required: () => !!this.capital || "Это поле обязательно для заполнения",
        requiredStr: (v) =>
          (typeof v === "string" && v.trim() !== "") ||
          "Это поле обязательно для заполнения",
        required: (v) => !!v || "Это поле обязательно для заполнения",
        // fio: (v) =>
        //   /^[А-ЯЁ][а-яё-]*([-][А-ЯЁ][а-яё-]*)?\s[А-ЯЁ][а-яё-]*\s[А-ЯЁ][а-яё]*$/gi.test(
        //     v
        //   ) || "Некорректно введено ФИО",
        text: (v) =>
          /^[А-ЯЁ][а-яё-]*([-][А-ЯЁ][а-яё-]*)?$/gi.test(v) ||
          "Только кириллические символы",
        min: () =>
          this.capital >= 10000 || "Минимумальный уставной капитал 10 000 руб.",
        serialNumber: (v) =>
          /([0-9]{4})[-\s]([0-9]{6})/gi.test(v) ||
          "Некорректно введены серия и номер", //#### ######
        branchCode: (v) =>
          /([0-9]{3})[-]([0-9]{3})/gi.test(v) ||
          "Некорректно введен код подразделения", //###-###
        inn: (v) => !v || this.checkInn(v) || "Указан неверный ИНН", //############
        snils: (v) =>
          !v ||
          /([0-9]{3})[-]([0-9]{3})[-]([0-9]{3})[ ]([0-9]{2})/gi.test(v) ||
          "Указан неверный СНИЛС", //###-###-### ##
        date: (v) =>
          (v !== null &&
            new Date(v.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")) >
              new Date("01/01/1901") &&
            new Date(v.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")) <
              new Date()) ||
          "Некорректно введена дата",
        phone: (v) =>
          /\+7 ([0-9]{3}) ([0-9]{3})-([0-9]{2})-([0-9]{2})/gi.test(v) ||
          "Некорректно введен номер телефона",
        address: (v) =>
          this.appdoc.address.house !== null ||
          "Некорректно введен адрес (заполните дом/сооружение/здание/строение)",
      },
    };
  },
  computed: {
    years() {
      return "года";
    },
    computedBirthDate: {
      get() {
        return this.formatDate(this.appdoc.birthDate);
      },
      set(value) {
        this.appdoc.birthDate = value;
      },
    },
    computedPassportDate: {
      get() {
        return this.formatDate(this.appdoc.passportDate);
      },
      set(value) {
        this.appdoc.passportDate = value;
      },
    },
  },
  watch: {
    appdoc: {
      handler(val, oldVal) {
        this.$refs.mainForm.validate();
      },
      deep: true,
    },
    addressSearch(value) {
      let options = {
        method: "post",
        url: process.env.VUE_APP_API + "/s/address",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({ query: value, count: 10 }),
      };
      this.axios(options)
        .then((result) => {
          let suggestions = result.data.suggestions.map(function (suggestion) {
            return suggestion.value;
          });
          this.addressItems = suggestions;
          this.addressLoading = false;
        })
        .catch((error) => console.log("error", error));
    },
    "appdoc.address.source"(value) {
      if (value) {
        let options = {
          method: "post",
          url: process.env.VUE_APP_API + "/s/address",
          mode: "cors",
          data: JSON.stringify({ query: value }),
        };
        this.axios(options)
          .then((result) => {
            this.appdoc.address = result.data;
          })
          .catch((error) => console.log("error", error));
      }
    },
    "appdoc.fmsCode"(value) {
      let options = {
        method: "post",
        url: process.env.VUE_APP_API + "/s/fms_unit",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({ query: value }),
      };
      this.axios(options)
        .then((result) => {
          let suggestions = result.data.suggestions.map(function (suggestion) {
            return suggestion.value;
          });
        })
        .catch((error) => console.log("error", error));
    },
    fms(value) {
      let options = {
        method: "post",
        url: process.env.VUE_APP_API + "/s/fms_unit",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({ query: value }),
      };
      this.axios(options)
        .then((result) => {
          let suggestions = result.data.suggestions.map(function (suggestion) {
            return suggestion.value;
          });
        })
        .catch((error) => console.log("error", error));
    },
    // fio(value) {
    //   this.fioLoading = true;
    //   let options = {
    //     method: "post",
    //     url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio",
    //     mode: "cors",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //     },
    //     data: JSON.stringify({ query: value, count: 10 }),
    //   };
    //   this.axios(options)
    //     .then((result) => {
    //       let suggestions = result.data.suggestions.map(function (suggestion) {
    //         return suggestion.value;
    //       });
    //       this.fioItems = suggestions;
    //       this.fioLoading = false;
    //     })
    //     .catch((error) => console.log("error", error));
    // },
    // "appdoc.fullName"(value) {
    //   if (this.appdoc.fullName) {
    //     let options = {
    //       method: "post",
    //       url: process.env.VUE_APP_API + "/s/name",
    //       mode: "cors",
    //       data: JSON.stringify({ query: value }),
    //     };
    //     this.axios(options)
    //       .then((result) => {
    //         this.appdoc.firstName = result.data.name;
    //         this.appdoc.lastName = result.data.surname;
    //         this.appdoc.patronymic = result.data.patronymic;
    //         if (result.data.gender === "М") this.appdoc.sex = 1;
    //         else if (result.data.gender === "Ж") this.appdoc.sex = 2;
    //       })
    //       .catch((error) => console.log("error", error));
    //   }
    // },
  },
  mounted() {
    if (localStorage.getItem("appdoc")) {
      let appdoc = JSON.parse(localStorage.getItem("appdoc"));
      this.appdoc = appdoc;
      this.addressItems.push(this.appdoc.address.source);
      // this.fioItems.push(this.appdoc.fullName);
    }
    if (this.$store.state.formValidation) {
      var self = this;
      setTimeout(function () {
        if (!localStorage.getItem("appdoc") || !self.next(false)) {
          self.$store.commit("formValidation", false);
        }
      }, 0);
    }
  },
  methods: {
    back() {
      this.$emit("pushPrev");
    },
    next(witherror = true) {
      if (!this.validate(witherror)) {
        return false;
      }
      this.loading = true;
      const payload = this.appdoc;
      if (window.dataLayer !== undefined) {
        window.dataLayer.push({ event: "ipNameSent" });
      }
      localStorage.setItem("appdoc", JSON.stringify(payload));
      this.$store.commit("updateAppdoc", payload);
      this.$emit("pushNext");
      this.loading = false;
      return true;
    },
    // fullName(firstName, lastName) {
    //   return firstName + " " + lastName;
    // },
    saveUser(id) {
      this.editFounder = false;
    },
    validate(witherror = true) {
      var result = this.$refs.mainForm.validate();
      if (!witherror && !result) {
        this.$refs.mainForm.resetValidation();
      }
      setTimeout(
        function () {
          this.$store.commit("isFormValid", ["appdoc", result]);
        }.bind(this),
        0
      );
      return result;
    },
    clearIfEmpty(e, obj) {
      if (!e.target.value) {
        Vue.set(obj, "source", e.target.value);
      }
    },
    setInputHandlers() {
      setTimeout(
        function () {
          var self = this;
          self.$refs.autocomplete.$refs.input.addEventListener(
            "focus",
            function () {
              self.$refs.autocomplete.isMenuActive = true;
            },
            true
          );
        }.bind(this),
        500
      );
    },
    formatDate(date) {
      if (!date) return null;
      if (date.split("-").length > 1) {
        const [day, month, year] = date.split("-");
        return `${day}.${month}.${year}`;
      }
      return date;
    },
    saveContent(e, obj, key) {
      this.$set(obj, key, e);
      const payload = this.appdoc;
      localStorage.setItem("appdoc", JSON.stringify(payload));
    },
  },
};
</script>
