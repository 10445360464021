<template>
  <v-form ref="mainForm" lazy-validation v-model="isFormValid">
    <money-text-field
      v-model="capital"
      label="Уставной капитал"
      filled
      :rules="[rules.required, rules.min]"
      :messages="['Минимум 10 000 руб.']"
      class="rounded-0"
      background-color="field_bg"
      @input="changedCapital"
    />

    <div class="text-h5 my-4">Учредители</div>
    <v-alert
      v-if="founders.length <= 0 && errors.founders"
      dense
      outlined
      type="error"
    >
      {{ errors.founders }}
    </v-alert>
    <template v-if="founders">
      <v-row v-for="founder in founders" :key="founder.id" class="mb-6 mb-sm-4">
        <v-col class="col-12 col-sm-4 pb-6 pb-sm-3">
          <v-text-field
            label="ФИО"
            readonly
            filled
            dense
            background-color="field_bg"
            :value="
              founder.lastName +
              ' ' +
              Array.from(founder.firstName)[0] +
              '.' +
              Array.from(founder.patronymic)[0] +
              '.'
            "
            hide-details="auto"
            class="rounded-0"
          />
        </v-col>
        <v-col class="col-6 col-sm-3">
          <percent-text-field
            v-model="founder.capitalSharePercent"
            label="Доля в процентах"
            filled
            :rules="[rules.requiredStr, rules.share]"
            hide-details="auto"
            class="rounded-0"
            background-color="field_bg"
            dense
            @input="changedCapitalSharePercent(founder.id)"
          />
        </v-col>
        <v-col class="col-6 col-sm-3">
          <money-text-field
            v-model="founder.capitalShareValue"
            label="Доля в деньгах"
            filled
            hide-details="auto"
            class="rounded-0"
            background-color="field_bg"
            dense
            @input="changedCapitalShareValue(founder.id)"
          />
        </v-col>
        <v-col class="col-sm-2 d-flex align-center">
          <v-row>
            <v-col class="col-6 col-xl-3">
              <v-btn icon class="_white-xs" @click="editFounder(founder.id)">
                <v-icon>mdi-lead-pencil</v-icon>
              </v-btn>
            </v-col>
            <v-col class="col-6 col-xl-3">
              <v-btn icon class="_white-xs" @click="removeFounder(founder.id)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <v-btn
      color="primary"
      class="_full-width-xs"
      @click="showUserForm('founder')"
    >
      Добавить учредителя
    </v-btn>
    <v-divider class="mt-8 mb-6" />
    <div class="text-h5 my-4">Руководитель организации</div>
    <template v-if="head">
      <v-row>
        <v-col cols="5">
          <v-text-field
            label="ФИО"
            readonly
            filled
            dense
            background-color="field_bg"
            :value="
              head.lastName +
              ' ' +
              Array.from(head.firstName)[0] +
              '.' +
              Array.from(head.patronymic)[0] +
              '.'
            "
            hide-details="auto"
            class="rounded-0"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            :value="headTitle"
            @input="debounceInput($event, 'saveContent', null, 'headTitle')"
            label="Должность"
            filled
            class="rounded-0"
            background-color="field_bg"
            hide-details="auto"
            dense
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            :value="headValidity"
            @input="debounceInput($event, 'saveContent', null, 'headValidity')"
            label="Срок избрания"
            filled
            class="rounded-0"
            background-color="field_bg"
            hide-details="auto"
            dense
            :suffix="years"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            :value="head.address.source"
            @input="
              debounceInput($event, 'saveContent', head.address, 'source')
            "
            :rules="[rules.requiredStr, rules.address]"
            validate-on-blur
            :items="addressItems"
            :loading="addressLoading"
            :search-input.sync="addressSearch"
            filled
            no-filter
            label="Адрес постоянной регистрации руководителя"
            background-color="field_bg"
            hide-details="auto"
            hide-no-data
          />
        </v-col>
      </v-row>
      <v-row class="mb-6 mb-sm-4">
        <v-col cols="6">
          <v-text-field
            :value="head.snils"
            @input="debounceInput($event, 'saveContent', head, 'snils')"
            v-mask="'###-###-### ##'"
            filled
            dense
            label="СНИЛС"
            background-color="field_bg"
            :rules="[rules.snils]"
            validate-on-blur
            hide-details="auto"
          />
        </v-col>
      </v-row>
    </template>
    <p v-else>
      Вы сможете выбрать руководителя из добавленных учредителей организации.
    </p>
    <v-alert v-if="!head && errors.head" dense outlined type="error">
      {{ errors.head }}
    </v-alert>
    <template v-if="founders.length <= 0">
      <v-tooltip bottom nudge-bottom="3">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-btn color="primary" class="_full-width-xs" disabled>
              Добавить руководителя
            </v-btn>
          </span>
        </template>
        <span>Начните с добавления учредителей</span>
      </v-tooltip>
    </template>
    <v-menu offset-y v-else>
      <template v-slot:activator="{ attrs, on }">
        <v-btn v-bind="attrs" color="primary" class="_full-width-xs" v-on="on">
          Добавить руководителя
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="founder in founders"
          :key="founder.id"
          link
          @click="addHeadFromFounder(founder.id)"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            v-text="
              founder.lastName +
              ' ' +
              Array.from(founder.firstName)[0] +
              '.' +
              Array.from(founder.patronymic)[0] +
              '.'
            "
          />
        </v-list-item>
        <v-list-item link @click="showUserForm('head')">
          <v-list-item-icon>
            <v-icon>mdi-account-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold">
            Добавить нового
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider class="mt-8 mb-8" />
    <v-row>
      <v-col>
        <v-btn color="secondary" class="text--primary" @click="back()">
          Назад
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="accent"
          class="text--primary"
          @click="next"
          :disabled="!isFormValid"
        >
          Сохранить &rarr;
        </v-btn>
      </v-col>
    </v-row>
    <!-- ########## DIALOG ########## -->
    <v-dialog
      v-model="addUserForm"
      scrollable
      max-width="720px"
      click:outside="closeForm"
    >
      <v-form ref="currentUser" v-model="currentUser.valid">
        <v-card color="appbg" class="py-8">
          <v-card-text class="px-4">
            <v-container class="pt-0">
              <h2 class="mb-4">Личные данные</h2>
              <v-row>
                <v-col class="col-12 col-sm-6">
                  <v-text-field
                    label="Гражданство"
                    dense
                    filled
                    disabled
                    background-color="field_bg"
                    value="Российская федерация"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="currentUser.lastName"
                    :rules="[rules.required, rules.text]"
                    filled
                    no-filter
                    label="Фамилия"
                    background-color="field_bg"
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="currentUser.firstName"
                    :rules="[rules.required, rules.text]"
                    filled
                    no-filter
                    label="Имя"
                    background-color="field_bg"
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="currentUser.patronymic"
                    :rules="[rules.required, rules.text]"
                    filled
                    no-filter
                    label="Отчество"
                    background-color="field_bg"
                    hide-details="auto"
                  />
                </v-col>

                <!-- <v-col cols="12">
                  <v-combobox v-model="currentUser.fullName" :items="fioItems" :loading="fioLoading"
                    :search-input.sync="fio" :rules="[rules.required, rules.fio]" filled no-filter label="ФИО"
                    hide-no-data background-color="field_bg" hide-details="auto" />
                </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="computedBirthDate"
                    v-mask="'##.##.####'"
                    :rules="[rules.required, rules.date]"
                    placeholder="01.01.1980"
                    label="Дата рождения"
                    filled
                    background-color="field_bg"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="currentUser.sex"
                    :items="[
                      { text: 'Мужской', value: 1 },
                      { text: 'Женский', value: 2 },
                    ]"
                    label="Пол"
                    required
                    dense
                    filled
                    background-color="field_bg"
                    :rules="[rules.required]"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
              <h2 class="mt-8 mb-4">Паспортные данные</h2>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="currentUser.passportNum"
                    v-mask="'#### ######'"
                    dense
                    label="Серия и номер паспорта"
                    filled
                    background-color="field_bg"
                    :rules="[rules.requiredStr, rules.serialNumber]"
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="currentUser.fmsCode"
                    v-mask="'###-###'"
                    dense
                    filled
                    label="Код подразделения"
                    background-color="field_bg"
                    :rules="[rules.requiredStr, rules.branchCode]"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-tooltip bottom nudge-bottom="2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="currentUser.fms"
                        dense
                        filled
                        label="Кем выдан"
                        background-color="field_bg"
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>Укажите точно как в паспорте</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="computedPassportDate"
                    v-mask="'##.##.####'"
                    :rules="[rules.required, rules.date]"
                    placeholder="01.01.1980"
                    label="Дата выдачи паспорта"
                    filled
                    background-color="field_bg"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="currentUser.birthPlace"
                    :rules="[rules.requiredStr]"
                    filled
                    label="Место рождения как в паспорте"
                    placeholder="Г. МОСКВА"
                    background-color="field_bg"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-12 col-sm-6">
                  <v-text-field
                    v-model="currentUser.inn"
                    v-mask="'############'"
                    filled
                    dense
                    background-color="field_bg"
                    label="ИНН"
                    :rules="[rules.inn]"
                    hide-details="auto"
                  />
                  <small>
                    <a href="https://service.nalog.ru/inn.do" target="_blank"
                      >Найти ИНН на nalog.ru</a
                    >
                  </small>
                  <div v-if="!currentUser.inn">
                    <small>
                      Отсутствие ИНН, если он был присвоен физическому лицу,
                      является причиной отказа в регистрации.
                    </small>
                  </div>
                </v-col>
                <v-col class="col-12 col-sm-6">
                  <v-text-field
                    filled
                    dense
                    background-color="field_bg"
                    label="Телефон"
                    placeholder="+7"
                    v-mask="'+7 ### ###-##-##'"
                    v-model="currentUser.phone"
                    :rules="[rules.requiredStr, rules.phone]"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions class="px-3 pt-8 pb-0 d-flex justify-space-between">
              <v-btn
                text
                color="secondary"
                class="text--primary"
                elevation="2"
                @click="closeForm"
              >
                Отмена
              </v-btn>
              <v-btn
                color="accent"
                class="text--primary"
                @click="addCurrentUser(currentUser.id)"
                :disabled="!currentUser.valid || !isFormValid"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- ########## DIALOG ########## -->
  </v-form>
</template>

<script>
import Vue from "vue";
import MoneyTextField from "@/components/MoneyTextField";
import PercentTextField from "@/components/PercentTextField";

export default {
  components: {
    MoneyTextField,
    PercentTextField,
  },
  data() {
    return {
      formType: null,
      addUserForm: false,
      addressItems: [],
      addressLoading: false,
      addressSearch: null,
      // fioItems: [],
      // fioLoading: false,
      // fio: null,
      fms: null,
      error: false,
      isFormValid: false,
      errorText:
        "Распределите оставшуюся часть уставного капитала между учредителями",
      errors: {
        head: null,
        founders: null,
      },
      currentUser: {
        id: null,
        firstName: null,
        lastName: null,
        fullName: null,
        patronymic: null,
        sex: null,
        inn: null,
        snils: null,
        birthDate: null,
        birthPlace: null,
        passportNum: null,
        passportDate: null,
        fms: null,
        fmsCode: null,
        phone: null,
        valid: false,
        capitalShareValue: null,
        capitalSharePercent: null,
        address: {
          postal_code: null,
          region_code: null,
          area_type: null,
          area: null,
          city_type: null,
          city: null,
          settlement_type: null,
          settlement: null,
          street_type: null,
          street: null,
          house_type_full: null,
          house: null,
          block_type_full: null,
          block: null,
          flat_type_full: null,
          flat: null,
          result: null,
          source: null,
        },
      },
      cleanUser: {
        id: null,
        firstName: null,
        lastName: null,
        patronymic: null,
        sex: null,
        inn: null,
        snils: null,
        birthDate: null,
        birthPlace: null,
        passportNum: null,
        passportDate: null,
        fms: null,
        fmsCode: null,
        phone: null,
        capitalShareValue: null,
        capitalSharePercent: null,
        address: {
          postal_code: null,
          region_code: null,
          area_type: null,
          area: null,
          city_type: null,
          city: null,
          settlement_type: null,
          settlement: null,
          street_type: null,
          street: null,
          house_type_full: null,
          house: null,
          block_type_full: null,
          block: null,
          flat_type_full: null,
          flat: null,
          result: null,
          source: null,
        },
      },
      capital: 10000,
      headTitle: "Генеральный директор",
      headValidity: 3,
      founders: [],
      head: null,
      rules: {
        //required: () => !!this.capital || "Это поле обязательно для заполнения",
        requiredStr: (v) =>
          (typeof v === "string" && v.trim() !== "") ||
          "Это поле обязательно для заполнения",
        required: (v) => !!v || "Это поле обязательно для заполнения",
        // fio: (v) =>
        //   /^[А-ЯЁ][а-яё-]*([-][А-ЯЁ][а-яё-]*)?\s[А-ЯЁ][а-яё-]*\s[А-ЯЁ][а-яё]*$/gi.test(
        //     v
        //   ) || "Некорректно введено ФИО",
        min: () =>
          this.capital >= 10000 || "Минимальный уставной капитал 10 000 руб.",
        serialNumber: (v) =>
          /([0-9]{4})[-\s]([0-9]{6})/gi.test(v) ||
          "Некорректно введены серия и номер", //#### ######
        branchCode: (v) =>
          /([0-9]{3})[-]([0-9]{3})/gi.test(v) ||
          "Некорректно введен код подразделения", //###-###
        inn: (v) => !v || this.checkInn(v) || "Указан неверный ИНН", //############
        snils: (v) =>
          !v ||
          /([0-9]{3})[-]([0-9]{3})[-]([0-9]{3})[ ]([0-9]{2})/gi.test(v) ||
          "Указан неверный СНИЛС", //###-###-### ##
        date: (v) =>
          (v !== null &&
            new Date(v.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")) >
              new Date("01/01/1901") &&
            new Date(v.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")) <
              new Date()) ||
          "Некорректно введена дата",
        phone: (v) =>
          /\+7 ([0-9]{3}) ([0-9]{3})-([0-9]{2})-([0-9]{2})/gi.test(v) ||
          "Некорректно введен номер телефона",
        address: (v) =>
          this.head.address.house !== null ||
          "Некорректно введен адрес (заполните дом/сооружение/здание/строение)",
        share: this.checkShare, //############
      },
    };
  },
  computed: {
    years() {
      return "года";
    },
    computedBirthDate: {
      get() {
        return this.formatDate(this.currentUser.birthDate);
      },
      set(value) {
        this.currentUser.birthDate = value;
      },
    },
    computedPassportDate: {
      get() {
        return this.formatDate(this.currentUser.passportDate);
      },
      set(value) {
        this.currentUser.passportDate = value;
      },
    },
  },
  watch: {
    founders: {
      handler(val, oldVal) {
        this.$refs.mainForm.validate();
        // this.validate();
      },
      deep: true,
    },
    head: {
      handler(val, oldVal) {
        this.$refs.mainForm.validate();
        if (val) {
          Vue.set(this.errors, "head", null);
        }
      },
      deep: true,
    },
    addressSearch(value) {
      let options = {
        method: "post",
        url: process.env.VUE_APP_API + "/s/address",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({ query: value, count: 10 }),
      };
      this.axios(options)
        .then((result) => {
          let suggestions = result.data.suggestions.map(function (suggestion) {
            return suggestion.value;
          });
          this.addressItems = suggestions;
          this.addressLoading = false;
        })
        .catch((error) => console.log("error", error));
    },
    "head.address.source"(value) {
      if (value) {
        let options = {
          method: "post",
          url: process.env.VUE_APP_API + "/s/address",
          mode: "cors",
          data: JSON.stringify({ query: value }),
        };
        this.axios(options)
          .then((result) => {
            this.head.address = result.data;
          })
          .catch((error) => console.log("error", error));
      }
    },
    "currentUser.fmsCode"(value) {
      let options = {
        method: "post",
        url: process.env.VUE_APP_API + "/s/fms_unit",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({ query: value }),
      };
      this.axios(options).then((result) => {
        let suggestions = result.data.suggestions.map(function (suggestion) {
          return suggestion.value;
        });
      });
    },
    fms(value) {
      let options = {
        method: "post",
        url: process.env.VUE_APP_API + "/s/fms_unit",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({ query: value }),
      };
      this.axios(options).then((result) => {
        let suggestions = result.data.suggestions.map(function (suggestion) {
          return suggestion.value;
        });
      });
    },
    // fio(value) {
    //   this.fioLoading = true;
    //   let options = {
    //     method: "post",
    //     url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio",
    //     mode: "cors",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //     },
    //     data: JSON.stringify({ query: value, count: 10 }),
    //   };
    //   this.axios(options).then((result) => {
    //     let suggestions = result.data.suggestions.map(function (suggestion) {
    //       return suggestion.value;
    //     });
    //     this.fioItems = suggestions;
    //     this.fioLoading = false;
    //   });
    // },
    // "currentUser.fullName"(value) {
    //   if (this.currentUser.fullName) {
    //     let options = {
    //       method: "post",
    //       url: process.env.VUE_APP_API + "/s/name",
    //       mode: "cors",
    //       data: JSON.stringify({ query: value }),
    //     };
    //     this.axios(options).then((result) => {
    //       this.currentUser.firstName = result.data.name;
    //       this.currentUser.lastName = result.data.surname;
    //       this.currentUser.patronymic = result.data.patronymic;
    //       if (result.data.gender === "М") this.currentUser.sex = 1;
    //       else if (result.data.gender === "Ж") this.currentUser.sex = 2;
    //     });
    //   }
    // },
  },
  mounted() {
    if (localStorage.getItem("founders")) {
      let founders = JSON.parse(localStorage.getItem("founders"));
      this.founders = founders.founders;
      this.head = founders.head.data;
      if (
        this.head !== null &&
        this.head.address !== null &&
        this.head.address.source !== null
      ) {
        this.addressItems.push(this.head.address.source);
      }
      if (this.head !== null && this.head.snils == null) {
        this.head.snils = null;
      }
      this.headTitle = founders.head.title;
      this.headValidity = founders.head.validity;
      this.capital = founders.capital;
    }
    if (this.$store.state.formValidation) {
      var self = this;
      setTimeout(function () {
        if (!localStorage.getItem("founders") || !self.next()) {
          self.$store.commit("formValidation", false);
        }
      }, 100);
    }
  },
  methods: {
    checkShare() {
      let summ = 0;
      this.founders.forEach(function (item) {
        summ += parseFloat(item.capitalSharePercent);
      });
      return (
        (Math.round(summ) >= 99.99 && Math.round(summ) <= 100) ||
        "Суммарная доля должна составлять 100%"
      );
    },
    back() {
      this.$emit("pushPrev");
    },
    next() {
      if (!this.validate(true)) {
        return false;
      }
      this.$store.commit("toggleFounders", {
        founders: this.founders,
        head: this.head,
      });
      if (this.founders.length <= 1 && this.founders[0].id == this.head.id) {
        Vue.set(
          this.$router.options.routes[2].children[5].meta,
          "disabled",
          false
        );
        var method = JSON.parse(localStorage.getItem("method"));
        if (
          (this.$store.state.document.method &&
            this.$store.state.document.method.status == 1) ||
          (method && method.status == 1)
        ) {
          Vue.set(
            this.$router.options.routes[2].children[8].meta,
            "disabled",
            true
          );
          Vue.set(
            this.$router.options.routes[2].children[9].meta,
            "disabled",
            false
          );
          Vue.set(this.$store.state.steps.legal[8], "disabled", true);
          Vue.set(this.$store.state.steps.legal[9], "disabled", false);
          // Changing bank to sign
          Vue.set(
            this.$router.options.routes[2].children[6].meta,
            "disabled",
            true
          );
          Vue.set(
            this.$router.options.routes[2].children[7].meta,
            "disabled",
            false
          );
          Vue.set(this.$store.state.steps.legal[6], "disabled", true);
          Vue.set(this.$store.state.steps.legal[7], "disabled", false);
        } else {
          // Changing sign to bank
          Vue.set(
            this.$router.options.routes[2].children[6].meta,
            "disabled",
            false
          );
          Vue.set(
            this.$router.options.routes[2].children[7].meta,
            "disabled",
            true
          );
          Vue.set(this.$store.state.steps.legal[6], "disabled", false);
          Vue.set(this.$store.state.steps.legal[7], "disabled", true);
        }
      } else {
        Vue.set(
          this.$router.options.routes[2].children[5].meta,
          "disabled",
          true
        );
        Vue.set(
          this.$router.options.routes[2].children[8].meta,
          "disabled",
          false
        );
        Vue.set(
          this.$router.options.routes[2].children[9].meta,
          "disabled",
          true
        );
        Vue.set(this.$store.state.steps.legal[8], "disabled", false);
        Vue.set(this.$store.state.steps.legal[9], "disabled", true);
        // Changing sign to bank
        Vue.set(
          this.$router.options.routes[2].children[6].meta,
          "disabled",
          false
        );
        Vue.set(
          this.$router.options.routes[2].children[7].meta,
          "disabled",
          true
        );
        Vue.set(this.$store.state.steps.legal[6], "disabled", false);
        Vue.set(this.$store.state.steps.legal[7], "disabled", true);
      }
      this.loading = true;
      const payload = {
        capital: this.capital,
        founders: this.founders,
        head: {
          data: this.head,
          title: this.headTitle,
          validity: this.headValidity,
        },
      };
      if (window.dataLayer !== undefined) {
        window.dataLayer.push({ event: "foundersSent" });
      }
      localStorage.setItem("founders", JSON.stringify(payload));
      this.$store.commit("updateFounders", payload);
      this.$emit("pushNext");
      this.loading = false;
      return true;
    },
    addHeadFromFounder(id) {
      this.head = this.founders.find((x) => x.id === id);
      if (this.head.address == null || this.head.address.source == null) {
        this.head.address = { source: "" };
      }
      localStorage.setItem(
        "founders",
        JSON.stringify({
          capital: this.capital,
          founders: this.founders,
          head: {
            data: this.head,
            title: this.headTitle,
            validity: this.headValidity,
          },
        })
      );
    },
    addCurrentUser(id = 0) {
      if (this.$refs.currentUser.validate()) {
        if (this.formType === "head") {
          this.currentUser.id = Date.now().toString();
          this.head = this.currentUser;
        } else if (this.formType === "founder") {
          if (id) {
            let self = this;
            this.founders.forEach(function (item, i) {
              if (item.id == id) {
                self.founders[i] = self.currentUser;
              }
            });
          } else {
            this.currentUser.id = Date.now().toString();
            this.founders.push(this.currentUser);
            this.currentUser = JSON.parse(JSON.stringify(this.cleanUser));
          }
        }
        this.addUserForm = false;
        // this.currentUser = JSON.parse(JSON.stringify(this.cleanUser));
        this.recalcCapitalShares();
        localStorage.setItem(
          "founders",
          JSON.stringify({
            capital: this.capital,
            founders: this.founders,
            head: {
              data: this.head,
              title: this.headTitle,
              validity: this.headValidity,
            },
          })
        );
      }
    },
    showUserForm(type) {
      this.formType = type;
      this.addUserForm = true;
      // this.currentUser = JSON.parse(JSON.stringify(this.cleanUser));
      this.setInputHandlers();
    },
    closeForm() {
      this.addUserForm = false;
      // this.currentUser = JSON.parse(JSON.stringify(this.cleanUser));
      this.formType = null;
    },
    // fullName(firstName, lastName) {
    //   return firstName + " " + lastName;
    // },
    recalcCapitalShares() {
      var self = this;
      if (!self.capital) {
        self.capital = 10000;
      }
      this.founders.forEach(function (item, i) {
        self.founders[i].capitalSharePercent = 100 / self.founders.length;
        self.founders[i].capitalShareValue =
          self.capital / self.founders.length;
      });
    },
    changedCapitalShareValue(id) {
      this.founders[
        this.founders
          .map(function (e) {
            return e.id;
          })
          .indexOf(id)
      ].capitalSharePercent = (event.target.value * 100) / this.capital;
      localStorage.setItem(
        "founders",
        JSON.stringify({
          capital: this.capital,
          founders: this.founders,
          head: {
            data: this.head,
            title: this.headTitle,
            validity: this.headValidity,
          },
        })
      );
    },
    changedCapitalSharePercent(id) {
      this.founders[
        this.founders
          .map(function (e) {
            return e.id;
          })
          .indexOf(id)
      ].capitalShareValue = (this.capital * event.target.value) / 100;
      localStorage.setItem(
        "founders",
        JSON.stringify({
          capital: this.capital,
          founders: this.founders,
          head: {
            data: this.head,
            title: this.headTitle,
            validity: this.headValidity,
          },
        })
      );
    },
    changedCapital() {
      for (let i = 0; i < this.founders.length; i++) {
        this.founders[i].capitalShareValue =
          (this.capital * this.founders[i].capitalSharePercent) / 100;
      }
      localStorage.setItem(
        "founders",
        JSON.stringify({
          capital: this.capital,
          founders: this.founders,
          head: {
            data: this.head,
            title: this.headTitle,
            validity: this.headValidity,
          },
        })
      );
    },
    editFounder(id) {
      this.formType = "founder";
      this.currentUser = this.founders.find((x) => x.id === id);
      // this.fioItems.push(this.currentUser.fullName);
      // this.addressItems.push(this.currentUser.address.source);
      this.addUserForm = true;
      // this.editFounder = true;
      this.setInputHandlers();
      localStorage.setItem(
        "founders",
        JSON.stringify({
          capital: this.capital,
          founders: this.founders,
          head: {
            data: this.head,
            title: this.headTitle,
            validity: this.headValidity,
          },
        })
      );
    },
    saveUser(id) {
      this.editFounder = false;
    },
    removeFounder(id) {
      this.founders = this.founders.filter((arr) => arr.id != id);
      if (this.head && this.head.id == id) {
        Vue.set(this, "head", null);
      }
      this.recalcCapitalShares();
      this.validate();
    },
    validate(witherror = true) {
      var result = this.$refs.mainForm.validate();
      if (this.founders.length <= 0) {
        Vue.set(
          this.errors,
          "founders",
          "Необходимо добавить минимум одного учредителя"
        );
        result = false;
      }
      if (!this.head) {
        Vue.set(this.errors, "head", "Необходимо выбрать руководителя");
        result = false;
      }
      if (!witherror && !result) {
        this.$refs.mainForm.resetValidation();
      }
      setTimeout(
        function () {
          this.$store.commit("isFormValid", ["founders", result]);
        }.bind(this),
        0
      );
      return result;
    },
    clearIfEmpty(e, obj) {
      if (!e.target.value) {
        Vue.set(obj, "source", e.target.value);
      }
    },
    setInputHandlers() {
      setTimeout(
        function () {
          var self = this;
          if (self.$refs.autocomplete) {
            self.$refs.autocomplete.$refs.input.addEventListener(
              "focus",
              function () {
                self.$refs.autocomplete.isMenuActive = true;
              },
              true
            );
          }
        }.bind(this),
        500
      );
    },
    formatDate(date) {
      if (!date) return null;
      if (date.split("-").length > 1) {
        const [day, month, year] = date.split("-");
        return `${day}.${month}.${year}`;
      }
      return date;
    },
    saveContent(e, obj, key) {
      if (obj == null) {
        obj = this;
      }
      this.$set(obj, key, e);
      localStorage.setItem(
        "founders",
        JSON.stringify({
          capital: this.capital,
          founders: this.founders,
          head: {
            data: this.head,
            title: this.headTitle,
            validity: this.headValidity,
          },
        })
      );
    },
  },
};
</script>
<!-- <style scoped>
/* .col {
        padding-top: 0 !important;
        padding-left: 0 !important;
      } */
</style> -->
