<template>
  <div>
    <template v-if="$store.state.document.need_to_rent">
      <p class="mb-4 text-body-2">
        После аренды адреса вернитесь в редактирование документа и укажите
        полученный адрес.
      </p>
      <v-row class="mt-sm-16">
        <v-col>
          <v-btn color="secondary" class="text--primary" @click="back()">
            Назад
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <template v-if="status">
        <p class="mb-4 text-body-2">
          Отлично, ваша заявка сформирована и отправлена в банк. В ближайшее
          время с вами свяжется представитель банка и назначит встречу для
          выпуска ЭЦП.
        </p>
        <p class="text-h6">
          Остались вопросы? Напишите нам в Whatsapp, будем рады помочь!
        </p>
        <v-btn
          color="#53B471"
          href="https://wa.me/79957931007?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9+%D0%B4%D0%B5%D0%BD%D1%8C%21+%D0%A3+%D0%BC%D0%B5%D0%BD%D1%8F+%D0%B5%D1%81%D1%82%D1%8C+%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81"
          target="_blank"
          class="white--text"
        >
          <v-icon left> mdi-whatsapp </v-icon>Написать
        </v-btn>
      </template>
      <template v-else-if="!status">
        <p v-if="!phoneIsVerified" class="mb-6 text-body-2">
          Как с вами связаться для продолжения онлайн регистрации?
        </p>
        <v-form
          ref="mainForm"
          v-model="$store.state.isFormValid.documentOnline"
        >
          <v-text-field
            v-model="form.email"
            :rules="rules.emailRules"
            label="Укажите email"
            background-color="field_bg"
            hide-details="auto"
            class="pb-6 pb-sm-4"
            filled
          />
          <v-text-field
            v-if="!phoneIsVerified"
            v-model="form.phone"
            v-mask="'(###) ###-##-##'"
            :disabled="codeIsSent"
            label="Номер телефона"
            prefix="+7"
            :rules="rules.phoneRules"
            validate-on-blur
            autofocus
            filled
            background-color="field_bg"
            :append-icon="phoneIsVerified ? 'mdi-check-circle' : ''"
            hide-details="auto"
            class="pb-6 pb-sm-4"
          />

          <v-btn
            v-if="!phoneIsVerified"
            color="primary"
            class="mb-5"
            :loading="sending"
            :disabled="phoneIsVerified || codeIsSent || !phoneValid"
            @click="sendcode"
          >
            {{ sendButtonText }}
          </v-btn>
          <v-alert
            v-if="form.response.message"
            dense
            :type="form.response.status"
            v-html="form.response.message"
          />
          <v-text-field
            v-if="!phoneIsVerified"
            v-model="form.code"
            v-mask="'####'"
            :disabled="phoneIsVerified || !codeIsSent"
            :rules="rules.codeRules"
            label="Код подтверждения"
            filled
            background-color="field_bg"
            hide-details="auto"
            class="pb-6 pb-sm-8"
          />

          <v-divider class="mt-8 mb-8 hidden-sm-and-up" />
          <v-row class="mt-sm-16">
            <v-col>
              <v-btn color="secondary" class="text--primary" @click="back()">
                Назад
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn
                v-if="!status"
                color="accent"
                class="text--primary"
                @click="next"
                :disabled="!$store.state.isFormValid.documentOnline"
              >
                Отправить
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      status: false,
      id: null,
      valid: false,
      phoneDisabled: false,
      phoneIsVerified: false,
      codeVisible: false,
      codeDisabled: false,
      codeIsSent: false,
      codeIsWrong: false,
      sending: false,
      counter: 0,
      getStat: null,
      errors: {
        phone: null,
      },
      form: {
        phone: this.$auth.check() ? this.$auth.user().phone : null,
        email: this.$auth.check() ? this.$auth.user().email : null,
        code: null,
        response: {
          status: null,
          message: null,
        },
      },
      rules: {
        emailRules: [
          (v) => !!v || "Укажите свою электронную почту",
          (v) =>
            /.+@.+\..+/.test(v) || "Проверьте корректность электронной почты",
        ],
        phoneRules: [
          (v) => !!v || "Введите номер телефона",
          () => this.phoneValid || "Номер телефона должен состоять из 10 цифр",
        ],
        codeRules: [() => this.codeIsWrong || "Неправильный код подтверждения"],
      },
    };
  },
  computed: {
    sendButtonText() {
      if (this.counter) return "Повторная отправка";
      else return "Отправить код";
    },
    phoneValid() {
      if (this.form.phone) return this.form.phone.length == 15 ? true : false;
      else return false;
      // console.log(this.phoneNum.length)
    },
    phoneNum() {
      if (this.form.phone) return parseInt(this.form.phone.replace(/\D+/g, ""));
      return false;
    },
    codeValid() {
      if (this.form.code)
        if (this.form.code.length == 4 && !this.codeIsWrong) return true;
        else return false;
      else return false;
    },
  },
  watch: {
    "$store.state.islogin"(status) {
      if (status === false) {
        this.phoneIsVerified = false;
        this.codeIsSent = false;
        this.form.response.message = null;
        this.form.response.status = null;
        this.form.code = "";
      }
    },
    "form.code"(value) {
      if (value && value.length == 4) {
        const options = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: process.env.VUE_APP_API + "/check",
          mode: "cors",
          data: { id: localStorage.getItem("id"), code: this.form.code },
        };
        let self = this;
        this.axios(options)
          .then((result) => {
            if (result.data) {
              self.form.response = result.data;
              if (result.data.status == "success") {
                self.phoneIsVerified = true;
                self.codeIsWrong = false;
                self.$auth.fetch();
              }
            }
          })
          .catch(function (error) {
            if (error.response.data) {
              self.form.response = error.response.data;
            }
            if (error.response.data.message == "Wrong code") {
              self.codeIsWrong = true;
            }
          });
      }
    },
    "$store.state.phone"() {
      Vue.set(
        this.form,
        "phone",
        this.formatPhoneNumber(this.$store.state.phone)
      );
    },
    "$store.state.email"() {
      Vue.set(this.form, "email", this.$store.state.email);
    },
  },
  beforeDestroy() {
    if (this.getStat) {
      clearTimeout(this.getStat);
    }
  },
  mounted() {
    if (this.$auth.check()) {
      Vue.set(
        this.form,
        "phone",
        this.formatPhoneNumber(this.$auth.user().phone)
      );
      Vue.set(this.form, "email", this.$auth.user().email);
      // if (this.form.phone) {
      //   this.phoneIsVerified = true;
      // }
    }
    Vue.set(this.$store.state.steps.legal[8], "subtitle", "Контактные данные");
    this.$store.commit("formValidation", false);
  },
  methods: {
    countDown() {
      if (this.counter) {
        return setTimeout(() => {
          --this.counter;
          this.countDown();
        }, 1000);
      }
      this.phoneDisabled = false;
    },
    sendcode() {
      this.$refs.mainForm.resetValidation();
      var self = this;
      this.sending = true;
      const id = localStorage.getItem("id");
      const options = {
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: process.env.VUE_APP_API + "/send",
        mode: "cors",
        data: { id: id, phone: this.phoneNum },
      };
      this.axios(options)
        .then((result) => {
          self.form.response = result.data;
          if (
            result.data.status == "success" ||
            result.data.status == "warning"
          ) {
            self.codeIsSent = true;
            self.sending = false;
          }
        })
        .catch(function (error) {
          if (error.response.data) {
            self.form.response = error.response.data;
          }
          self.sending = false;
          // console.log(error);
        });
    },
    back() {
      this.$router.push("/legal/bank");
    },
    next() {
      if (!this.validate()) {
        return false;
      }
      var self = this;
      this.loading = true;
      if (window.dataLayer !== undefined) {
        if (this.$store.state.type == "legal") {
          window.dataLayer.push({ event: "successSentOnline" });
        } else {
          window.dataLayer.push({ event: "ipSuccessSentOnline" });
        }
      }
      const id = localStorage.getItem("id");
      this.id = id;
      let options = {
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: process.env.VUE_APP_API + "/applicant",
        data: {
          id: id,
          phone: this.phoneNum,
          code: this.form.code,
          email: this.form.email,
        },
      };
      this.axios(options)
        .then((result) => {
          if (result.data) {
            if (result.data.id) {
              self.status = true;
              self.form.response.message = null;
              self.form.response.status = null;
              // Vue.set(
              //   self.$store.state.steps.legal[8],
              //   "subtitle",
              //   "Спасибо, заявка отправлена"
              // );
            } else {
              self.status = false;
              self.form.response = result.data.result;
            }
          }
          if (result.headers.authorization) {
            localStorage.setItem("auth_token", result.headers.authorization);
          }
          localStorage.setItem(
            "applicant",
            JSON.stringify({ phone: this.form.phone, email: this.form.email })
          );
          //this.$store.commit("updateApplicant", {applicant: {phone: this.form.phone, email: this.form.email}});
        })
        .catch(function (error) {
          if (error.data) {
            self.form.response = error.data;
          }
          // console.log(error);
        });
      this.loading = false;
    },
    validate() {
      return this.$refs.mainForm.validate();
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
      if (match) {
        return (
          "(" + match[1] + ") " + match[2] + "-" + match[3] + "-" + match[4]
        );
      }
      return null;
    },
  },
};
</script>
